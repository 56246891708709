import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import messages from './NewMatrixEdgesListDialog.messages';
import { TEdgesListDialogProps } from './NewMatrixEdgesListDialog.types';
import theme from './NewMatrixEdgesListDialog.scss';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import {
    matrixDecompositionIconClick,
    matrixDeleteEdgeDefinition,
    newMatrixGetEdgesList,
} from '@/actions/entities/newMatrix.actions';
import { NewMatrixSelectors } from '@/selectors/entities/newMatrix.selectors';
import { TMatrixCellData } from '@/reducers/entities/newMatrix.reducer.types';
import { Table } from 'semantic-ui-react';
import { TEdgeIdWithType } from '../NewMatrix.types';
import { MatrixStyle, ModelAssignment, NodeId } from '@/serverapi/api';
import { renderCellSymbol } from '../../NewMatrix.utils';
import { Icon } from '@/modules/UIKit';
import icDelete from '@/resources/icons/delete_red.svg';
import icDeleted from '@/resources/icons/ic-g-u-blocked.svg';
import { Spinner } from '@/modules/Spinner/Spinner.component';
import { DECOMPOSITION_ICON } from '@/mxgraph/util/PortsDefinitions.utils';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { ConfirmationDialog } from './ConfirmationDialog.component';

export const MatrixEdgesListDialog: FC<TEdgesListDialogProps> = (props) => {
    const {
        open,
        nodeId,
        cellId,
        sourceName,
        targetName,
        cellStyles,
        sourceObjectDefinitionId,
        targetObjectDefinitionId,
    } = props;

    const [deleteDefinitionId, setDeleteDefinitionId] = useState<string>('');

    const cellData: TMatrixCellData | undefined = useSelector(NewMatrixSelectors.getCellData(nodeId, cellId));

    const dispatch = useDispatch();
    const intl = useIntl();

    useEffect(() => {
        dispatch(newMatrixGetEdgesList(nodeId, cellId, sourceObjectDefinitionId, targetObjectDefinitionId));
    }, []);

    const cancelHandler = () => {
        dispatch(closeDialog(DialogType.NEW_MATRIX_EDGES_LIST_DIALOG));
    };

    const handleDeleteObjectDefenition = (delteNodeId: NodeId) => {
        setDeleteDefinitionId('');
        dispatch(
            matrixDeleteEdgeDefinition(nodeId, delteNodeId, cellId, sourceObjectDefinitionId, targetObjectDefinitionId),
        );
    };

    const handleDecompositionClick = (edgeDefinitionId: string, modelAssignments: ModelAssignment[]) => {
        dispatch(matrixDecompositionIconClick(nodeId, edgeDefinitionId, modelAssignments));
        cancelHandler();
    };

    const renderEdgeTypeName = (edge: TEdgeIdWithType) => {
        return (
            <span className={edge.edgeTypeName ? '' : theme.deletedName}>{edge.edgeTypeName || edge.edgeTypeId}</span>
        );
    };

    const renderEdgeDefinitionButtons = (edge: TEdgeIdWithType) => {
        return (
            <>
                <Table.Cell>
                    <div className={theme.iconsContainer}>
                        <Icon
                            className={theme.control}
                            spriteSymbol={icDelete}
                            onClick={() => setDeleteDefinitionId(edge.id)}
                        />
                        {edge.modelAssignments?.length !== 0 && (
                            <img
                                className={theme.decompositionIcon}
                                src={DECOMPOSITION_ICON}
                                onClick={() => handleDecompositionClick(edge.id, edge.modelAssignments!)}
                            />
                        )}
                    </div>
                    <ConfirmationDialog
                        open={deleteDefinitionId === edge.id}
                        id={edge.id}
                        onCancel={() => setDeleteDefinitionId('')}
                        onDelete={() => handleDeleteObjectDefenition({ ...nodeId, id: edge.id })}
                    />
                </Table.Cell>
            </>
        );
    };

    const renderEdgeSymbol = (edge: TEdgeIdWithType) => {
        if (edge.edgeTypeName) {
            const currentStyle: MatrixStyle | undefined = cellStyles.find((style) => style.id === edge.edgeTypeId);
            const { symbol, color } = renderCellSymbol(currentStyle, theme.antdIconsContainer);

            return <span style={{ color }}>{symbol}</span>;
        }

        return <Icon spriteSymbol={icDeleted} />;
    };

    const renderTableBody = () => {
        if (cellData) {
            const { edgeDefinitions = [], edgeInstances = [] } = cellData;
            const allEdges: TEdgeIdWithType[] = [...edgeInstances, ...edgeDefinitions];

            return allEdges.map((edge) => {
                const elementType = edge.isEdgeInctance
                    ? intl.formatMessage(messages.instance)
                    : intl.formatMessage(messages.definition);

                return (
                    <Table.Row key={edge.id}>
                        <Table.Cell>{edge.id}</Table.Cell>
                        <Table.Cell>{elementType}</Table.Cell>
                        <Table.Cell>{edge.isOutgoingEdge ? sourceName : targetName}</Table.Cell>
                        <Table.Cell>{renderEdgeTypeName(edge)}</Table.Cell>
                        <Table.Cell>{edge.isOutgoingEdge ? targetName : sourceName}</Table.Cell>
                        <Table.Cell className={theme.symbolCell}>{renderEdgeSymbol(edge)}</Table.Cell>
                        {!edge.isEdgeInctance && renderEdgeDefinitionButtons(edge)}
                    </Table.Row>
                );
            });
        }

        return undefined;
    };

    const footer = (
        <div>
            <Button key="cancel" size="large" onClick={cancelHandler}>
                {intl.formatMessage(messages.close)}
            </Button>
        </div>
    );

    return (
        <>
            <Dialog
                onCancel={cancelHandler}
                title={intl.formatMessage(messages.title)}
                footer={footer}
                open={open}
                width={900}
            >
                <div className={theme.tableContainer}>
                    <Spinner loading={!!cellData?.isLoading} className={theme.spinner}>
                        {
                            // @ts-ignore
                            <Table definition className={theme.table}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>{intl.formatMessage(messages.ID)}</Table.HeaderCell>
                                        <Table.HeaderCell>{intl.formatMessage(messages.elementType)}</Table.HeaderCell>
                                        <Table.HeaderCell>{intl.formatMessage(messages.sourceObject)}</Table.HeaderCell>
                                        <Table.HeaderCell>{intl.formatMessage(messages.edgeType)}</Table.HeaderCell>
                                        <Table.HeaderCell>{intl.formatMessage(messages.targetObject)}</Table.HeaderCell>
                                        <Table.HeaderCell>{intl.formatMessage(messages.symbol)}</Table.HeaderCell>
                                        {cellData?.edgeDefinitions?.length !== 0 && <Table.HeaderCell />}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>{renderTableBody()}</Table.Body>
                            </Table>
                        }
                    </Spinner>
                </div>
            </Dialog>
        </>
    );
};
