import { FooterButtons } from '@/modules/Footer/FooterButtons.component';
import { ComplexSymbolManager } from '@/mxgraph/ComplexSymbols/ComplexSymbolManager.class';
import React from 'react';
import { useIntl } from 'react-intl';
import footerMessages from '../../../../../../../../Footer/FooterButtons.messages';
import { LocalesService } from '@/services/LocalesService';
import { MAX_GUID_LENGTH } from '@/utils/consts';
import { SymbolTypeId } from '@/mxgraph/ComplexSymbols/symbols/ComplexSymbol.constants';
import { MxCell } from 'MxGraph';
import { InternationalString, Symbol, SymbolCreationTypeEnum } from '@/serverapi/api';
import { Locale } from '@/modules/Header/components/Header/header.types';
import { TPreset } from '@/models/preset.types';
import { TreeNode } from '@/models/tree.types';

import theme from './Footer.scss';

const alwaysEditableSymbolsIds: string[] = [
    SymbolTypeId.CROSS,
    SymbolTypeId.HORIZONTAL_POOL,
    SymbolTypeId.HORIZONTAL_SWIMLANE,
    SymbolTypeId.VERTICAL_POOL,
    SymbolTypeId.VERTICAL_SWIMLANE,
];

interface IFooterProps {
    tmpSymbol: Symbol;
    symbol: Symbol;
    isEmptyName: boolean;
    synonymsIds: string[] | undefined;
    id: string;
    cell: MxCell | undefined;
    currentLocale: Locale;
    isNewSymbol: boolean;
    preset: TPreset;
    serverNode: TreeNode;
    multilingualName: InternationalString | undefined;
    onCancel: () => void;
    createSymbol: (preset: TPreset, serverNode: TreeNode, symbol: Symbol) => void;
    submitSymbol: (preset: TPreset, serverNode: TreeNode, symbol: Symbol) => void;
}

export const Footer = (props: IFooterProps) => {
    const {
        preset,
        serverNode,
        isNewSymbol,
        cell,
        id,
        synonymsIds,
        isEmptyName,
        symbol,
        tmpSymbol,
        onCancel,
        currentLocale,
        createSymbol,
        submitSymbol,
        multilingualName,
    } = props;

    const { icon, style, labelStyle, width, height, symbolTypeId, graphical, creationType, symbolSettings } = tmpSymbol;

    const geometry = ComplexSymbolManager.getSymbolPreviewGeometry(tmpSymbol, cell);

    const intl = useIntl();

    return (
        <div className={theme.root}>
            <FooterButtons
                buttons={[
                    {
                        children: intl.formatMessage(footerMessages.cancel),
                        onClick: onCancel,
                        size: 'large',
                    },
                    {
                        children: intl.formatMessage(footerMessages.save),
                        visualStyle: 'primary',
                        disabled:
                            isEmptyName ||
                            ((!width || !height) && alwaysEditableSymbolsIds.includes(symbolTypeId || '')) ||
                            !id.length ||
                            id.length > MAX_GUID_LENGTH,
                        onClick: () => {
                            const newSymbol = {
                                ...symbol,
                                graphical,
                                id,
                                synonymsIds,
                                multilingualName: multilingualName || symbol.multilingualName,
                                // для bpmn.
                                style: style || symbol.style,
                                labelStyle: labelStyle ?? symbol.labelStyle,
                                width: width || Number(symbol.width) || 150,
                                height: height || Number(symbol.height) || 100,
                                icon,
                                name: LocalesService.internationalStringToString(
                                    multilingualName || symbol.multilingualName,
                                    currentLocale,
                                ),
                                creationType: (creationType === 'SYMBOL_GENERATOR'
                                    ? 'SYMBOL_GENERATOR'
                                    : 'IMAGE') as SymbolCreationTypeEnum,
                                symbolSettings,
                                ...geometry,
                            };
                            if (isNewSymbol) {
                                createSymbol(preset, serverNode, newSymbol);
                            } else {
                                submitSymbol(preset, serverNode, newSymbol);
                            }
                        },
                        dataTest: 'symbol-editor_save-btn',
                        size: 'large',
                    },
                ]}
            />
        </div>
    );
};
