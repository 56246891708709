import { MethodologiesGraph } from '@/mxgraph/MethodologiesGraph';
import { FormattedMessage } from 'react-intl';
import { Symbol } from '@/serverapi/api';
import { MxCell, MxConstants } from 'MxGraph';
import React from 'react';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { DisableLabelCheckBox } from '@/modules/AdminTools/Methodology/components/Presets/ObjectType/DisableLabelCheckBox';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { Upload } from 'antd';

import messages from '../../../ObjectType.messages';
import theme from './IconTab.scss';

interface IIconTabProps {
    tmpSymbol: Symbol;
    symbol: Symbol;
    graph: MethodologiesGraph | undefined;
    cell: MxCell | undefined;
    setCell: React.Dispatch<React.SetStateAction<MxCell | undefined>>;
    isEditableSymbol: boolean;
    updateSymbol: (symbol: Symbol) => void;
    onChangeStyle: (key: string, value: any) => void;
    getStyleValue: (key: string, style?: string) => string | undefined;
}

export const IconTab = (props: IIconTabProps) => {
    const { isEditableSymbol, tmpSymbol, updateSymbol, graph, cell, onChangeStyle, getStyleValue } = props;
    const generateIcon = (): void => {
        if (cell) {
            const svg = graph?.getSvg({ cellsForCopy: [cell], onlySelectedCells: true });
            if (svg) {
                updateSymbol({
                    ...tmpSymbol,
                    icon: `data:image/svg+xml;base64,${window.btoa(svg.outerHTML)}`,
                });
            }
        }
    };

    const dropFile = (e: UploadRequestOption): void => {
        if (e.file) {
            const reader = new FileReader();
            reader.readAsDataURL(e.file as File);
            reader.onloadend = (): void => {
                updateSymbol({
                    ...tmpSymbol,
                    icon: reader.result as string,
                });
            };
        }
    };

    const checkboxChanged = (e: CheckboxChangeEvent): void => {
        onChangeStyle(MxConstants.STYLE_NOLABEL, e.target.checked ? '1' : undefined);
    };

    return (
        <div>
            <div className={theme.buttonContainer}>
                <Button visualStyle="primary" onClick={generateIcon}>
                    <FormattedMessage {...messages.createFromSymbol} />
                </Button>
            </div>
            <div>
                <FormattedMessage {...messages.uploadIcon} />
                <div className={theme.dragContainer}>
                    <Upload.Dragger name="file" multiple={false} showUploadList={false} customRequest={dropFile}>
                        <p className="ant-upload-text">
                            <FormattedMessage {...messages.uploadText} />
                        </p>
                    </Upload.Dragger>
                </div>
            </div>
            {!isEditableSymbol && (
                <DisableLabelCheckBox
                    checkboxChanged={checkboxChanged}
                    checked={Number(getStyleValue(MxConstants.STYLE_NOLABEL, tmpSymbol.labelStyle)) === 1}
                />
            )}
        </div>
    );
};
