import React, { useState, useMemo, FC } from 'react';
import { useIntl } from 'react-intl';
import theme from '../ObjectPropertiesDialog.scss';
import messages from './ModelEventsList.messages';
import { Form, Select, Button, Input } from 'antd';
import { EventRunStrategy, EventActions } from '../../../consts/movelEvents';
import { v4 as uuid } from 'uuid';
import { EventDescriptorMacros, Node, NodeId } from '../../../../serverapi/api';
import { EditOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { fetchNodes } from '../../../../actions/nodes.actions';
import { scriptSettingsSelectDialogInit } from '@/actions/scriptSelectDialog.actions';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { TreeNode } from '@/models/tree.types';

type TModelEventsListHeaderProps = {
    nodeId: NodeId;
    scripts: Node[];
    modelEvents: EventDescriptorMacros[];
    setModelEvents: (modelEvents: EventDescriptorMacros[]) => void;
};

export const ModelEventsListHeader: FC<TModelEventsListHeaderProps> = ({
    nodeId,
    scripts,
    modelEvents,
    setModelEvents,
}) => {
    const intl = useIntl();

    const dispatch = useDispatch();

    const [eventRunStrategy, setEventRunStrategy] = useState<EventRunStrategy | undefined>(undefined);
    const [eventActions, setEventAction] = useState<EventActions | undefined>(undefined);
    const [scriptId, setScriptId] = useState<NodeId | undefined>(undefined);

    const addButtonDisabled = useMemo(
        () =>
            !scriptId ||
            !eventActions ||
            !eventRunStrategy ||
            !!modelEvents.find(
                (event) =>
                    event.scriptId === scriptId.id &&
                    event.eventActions === eventActions &&
                    event.eventRunStrategy === eventRunStrategy,
            ),
        [scriptId, eventActions, eventRunStrategy, modelEvents],
    );

    const addModelEvent = () => {
        setModelEvents([
            ...modelEvents,
            {
                id: uuid(),
                eventRunStrategy,
                eventActions,
                scriptId: scriptId?.id,
            } as EventDescriptorMacros,
        ]);
    };

    const onSciptSelectSumbit = (nodeId: NodeId) => {
        setScriptId(nodeId);
        if (!scripts.find((script) => script.nodeId.id === nodeId.id)) dispatch(fetchNodes([nodeId]));
    };

    const onAddNode = () => {
        dispatch(scriptSettingsSelectDialogInit());
        dispatch(
            openDialog(DialogType.EVENT_SCRIPT_DIALOG, {
                serverId: nodeId.serverId,
                dialogType: DialogType.EVENT_SCRIPT_DIALOG,
                isEvent: true,
                onSubmit: (selectedScript: TreeNode) => onSciptSelectSumbit(selectedScript.nodeId),
            }),
        );
    };

    return (
        <Form className={theme.modelEventsListForm}>
            <Form.Item label={intl.formatMessage(messages.selectScript)} style={{ width: 200 }}>
                <div style={{ width: 200, display: 'flex' }}>
                    <Input
                        className={theme.node}
                        disabled
                        type="text"
                        value={scripts.find((script) => script.nodeId.id === scriptId?.id)?.name || ''}
                    />
                    <Button
                        className={theme.nodeButton}
                        icon={<EditOutlined />}
                        onClick={onAddNode}
                        style={{ marginLeft: 5 }}
                    />
                </div>
            </Form.Item>
            <Form.Item label={intl.formatMessage(messages.selectAction)} style={{ width: 150 }}>
                <Select
                    defaultValue={intl.formatMessage(messages.notSelected)}
                    onChange={(eventAction: EventActions) => setEventAction(eventAction)}
                    style={{ width: 150 }}
                >
                    {Object.keys(EventActions).map((action) => (
                        <Select.Option key={action} value={action}>
                            {messages[action] ? intl.formatMessage(messages[action]) : action}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item label={intl.formatMessage(messages.selectStrategy)} style={{ width: 150 }}>
                <Select
                    defaultValue={intl.formatMessage(messages.notSelected)}
                    onChange={(strategy: EventRunStrategy) => setEventRunStrategy(strategy)}
                    style={{ width: 150 }}
                >
                    {Object.keys(EventRunStrategy).map((strategy) => (
                        <Select.Option key={strategy} value={strategy}>
                            {messages[strategy] ? intl.formatMessage(messages[strategy]) : strategy}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item className={theme.modelEventsButton}>
                <Button onClick={addModelEvent} disabled={addButtonDisabled} type="primary">
                    {intl.formatMessage(messages.add)}
                </Button>
            </Form.Item>
        </Form>
    );
};
