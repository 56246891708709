import { FormattedMessage, useIntl } from 'react-intl';
import theme from './HomePageTab.scss';
import messages from '../../messages/HomePageTab.messages';
import { Tabs } from 'antd';
import { TRecentModel } from '../../../../models/recentModel.types';
import { ExtraButtonProperties, NodeId } from '../../../../serverapi/api';
import { formatDate } from '../../../../utils/date.time.utils';
import { TreeItemType } from '../../../Tree/models/tree';
import electron from '../../../../electron';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import { ExtraButtonType } from '../../../AdminTools/ServerSettings/ExtraButtonSettings.component';
import { Locale } from '../../../Header/components/Header/header.types';
import React, { FC } from 'react';
import { v4 as uuid } from 'uuid';
import { urlDataTextCheck } from '../../../UIKit/components/EditableText/editableText.utils';
import icDefaultImage from '../../../../resources/icons/ic-default-image.svg';
import { useExtraButtonCacheImages } from '../../../../hooks/useExtraButtonCacheImages';
import { LocalesService } from '../../../../services/LocalesService';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthorizationState, hasModelEditorLicense } from '../../../../selectors/authorization.selectors';
import { ServerProfileSelectors } from '../../../../selectors/serverProfile.selectors';
import { getRecentsByServerIds } from '../../../../selectors/recent.selector';
import { getCurrentLocale } from '../../../../selectors/locale.selectors';
import { modelDialogInit } from '../../../../actions/modelDialog.actions';
import { openNode, openNodeById } from '../../../../actions/openNode.actions';
import { ServerSelectors } from '../../../../selectors/entities/server.selectors';
import { TreeNode } from '../../../../models/tree.types';
import { moveToDirectAction } from '../../../../actions/editor.actions';
import { FavoriteTab } from '../FavoriteTab/FavoriteTab.component';
import { MyApprovalsTab } from '@/modules/MyApprovalsTab/MyApprovalsTab.component';
import { ApprovalSelectors } from '@/selectors/approval.selectors';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { NAVIGATOR_STRUCTURE } from '@/utils/consts';

const dateComparator = (a: TRecentModel, b: TRecentModel) => {
    const aDate = new Date(a.createdAt);
    const bDate = new Date(b.createdAt);

    return bDate.getTime() - aDate.getTime();
};

const MAX_LENGTH_RECENT_LIST = 10;
const NAME_MAX_LENGTH = 16;

export const HomePageTab: FC = React.memo(() => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { usersProperties, user } = useSelector(getAuthorizationState);
    const serverId = useSelector(ServerSelectors.connected)[0];
    const extraButtonProperties = usersProperties?.extraButtonProperties;
    const activeServerProfileId: string = useSelector(ServerProfileSelectors.activeProfileId) || '';
    const models = useSelector(getRecentsByServerIds(serverId));
    const locale = useSelector(getCurrentLocale);
    const isModelEditor: boolean = useSelector(hasModelEditorLicense);
    const approvalsToVoteCount: number = useSelector(ApprovalSelectors.getApprovalsToVoteCount);

    const { cacheFiles } = useExtraButtonCacheImages(extraButtonProperties || []);
    const approvalsTabName =
        approvalsToVoteCount === 0
            ? intl.formatMessage(messages.approvals)
            : `${intl.formatMessage(messages.approvals)} (${approvalsToVoteCount})`;

    const onAdd = () =>
        dispatch(
            modelDialogInit({
                parentNodeId: {
                    id: '',
                    serverId: '',
                    repositoryId: '',
                },
                notationId: 'bpmNotation',
                openedSelectNode: true,
            }),
        );
    const onModelClick = (nodeId: NodeId, type: TreeItemType) => {
        const treeNode: TreeNode = {
            hasChildren: false,
            nodeId,
            name: '',
            type,
            countChildren: 0,
        };

        dispatch(moveToDirectAction(treeNode, NAVIGATOR_STRUCTURE));
        dispatch(openNode({ nodeId, type }));
    };
    const onButtonClick = (nodeId: NodeId) => dispatch(openNodeById({ nodeId }));

    const getButtonUrl = (button: ExtraButtonProperties) => {
        const url = LocalesService.internationalStringToString(button.url, locale && Locale[locale]);

        return urlDataTextCheck(url, electron);
    };

    models.sort(dateComparator);
    const topModels = models
        .filter((m) => m && m.type && m.nodeId?.id && m.nodeId?.serverId)
        .slice(0, MAX_LENGTH_RECENT_LIST);

    const getButtonName = (button: ExtraButtonProperties) => {
        let localizedButtonName = LocalesService.internationalStringToString(button.name, locale && Locale[locale]);
        if (!localizedButtonName) {
            localizedButtonName = LocalesService.internationalStringToString(button.url, locale && Locale[locale]);
        }
        const buttonName =
            localizedButtonName && localizedButtonName.length > NAME_MAX_LENGTH
                ? `${localizedButtonName?.slice(0, NAME_MAX_LENGTH)}...`
                : localizedButtonName;

        return <div className={theme.buttonName}>{buttonName}</div>;
    };

    const getButton = (button: ExtraButtonProperties) => {
        return (
            <div
                className={theme.extraButtonWrapper}
                key={uuid()}
                onClick={() => {
                    if (button.type === ExtraButtonType.LINK) {
                        const buttonUrl = getButtonUrl(button);
                        if (buttonUrl) {
                            electron ? electron?.shell?.openExternal(buttonUrl) : window.open(buttonUrl);
                        }
                    } else {
                        onButtonClick({
                            ...button.nodeId!,
                            serverId,
                        });
                    }
                }}
            >
                <div className={theme.mainPageButton}>
                    <Button>
                        <div className={theme.extraButtonImgWrap}>
                            {button.imageId ? (
                                <img className={theme.image} src={cacheFiles[button.imageId]} alt="extraButton" />
                            ) : (
                                <Icon className={theme.defaultImageSize} spriteSymbol={icDefaultImage} />
                            )}
                        </div>

                        {getButtonName(button)}
                    </Button>
                </div>
            </div>
        );
    };

    const buttons: JSX.Element[] | undefined = extraButtonProperties
        ?.filter((b) => b.id)
        ?.filter(
            (b) =>
                b.profilesIds?.includes(activeServerProfileId) ||
                b.profilesIds?.some((id) => user?.profilesIds?.includes(id)) ||
                !b.profilesIds ||
                !b.profilesIds.length,
        )
        ?.map((extraButtonProperty) => getButton(extraButtonProperty));
    const addModelButtonClassName = `${theme.mainPageButton} ${theme.createModel} ${
        isModelEditor ? '' : theme.disabled
    }`;

    return (
        <div className={theme.container}>
            <div className={theme.creation}>
                <div className={isModelEditor ? '' : theme.addModelDisabledButtonContainer}>
                    <div className={addModelButtonClassName}>
                        <Button
                            dataTest="home-page-tab-add-model-button"
                            tooltip={isModelEditor ? '' : intl.formatMessage(messages.needLicense)}
                            disabled={!isModelEditor}
                            onClick={onAdd}
                        >
                            <div className={theme.modelIcon}>
                                <div className={theme.modelIconTop}>
                                    <div className={theme.square} />
                                </div>
                                <div className={theme.modelIconDivider} />
                                <div className={theme.modelIconBottom}>
                                    <div className={theme.square} />
                                    <div className={theme.square} />
                                    <div className={theme.square} />
                                </div>
                            </div>
                            <div>
                                <FormattedMessage {...messages.buttonCreationLabel} />
                            </div>
                        </Button>
                    </div>
                </div>
                {buttons}
            </div>

            <Tabs defaultActiveKey="recent" className={theme.tabs}>
                <Tabs.TabPane tab={intl.formatMessage(messages.recentTabTitle)} key="recent">
                    <div className={theme.list}>
                        {topModels.map((model, index: number) => {
                            const { title, createdAt: date, nodeId, modelTypeName, type, messageDescriptor } = model;
                            const name = messageDescriptor && intl.formatMessage(messageDescriptor);
                            const modelName = name || modelTypeName || intl.formatMessage(messages.emptyModelType);
                            const fdate = formatDate(
                                intl.formatMessage(messages.todayTimePrefix),
                                intl.formatMessage(messages.yesterdayTimePrefix),
                                locale,
                                new Date(date),
                            );

                            return (
                                <div className={theme.model} key={index} onClick={() => onModelClick(nodeId, type)}>
                                    <div className={theme.modelInfo}>
                                        <div className={theme.modelTitle}>{title}</div>
                                        <div className={theme.modelType}>{modelName}</div>
                                    </div>
                                    <div className={theme.time}>{fdate}</div>
                                </div>
                            );
                        })}
                    </div>
                </Tabs.TabPane>

                <Tabs.TabPane tab={intl.formatMessage(messages.favorite)} key="favorite">
                    <FavoriteTab />
                </Tabs.TabPane>

                <Tabs.TabPane tab={approvalsTabName} key="approvals">
                    <MyApprovalsTab />
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
});
