import * as React from 'react';
import { Dialog } from '../UIKit/components/Dialog/Dialog.component';
import theme from './ModelLinkDialog.scss';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../actions/dialogs.actions';
import { DialogType } from '../DialogRoot/DialogRoot.constants';
import messages from './ModelLinkDialog.messages';
import { OpenTabStatus } from '../../services/dao/TabsBusDAOService';
import { tabsBusAppExitRequestAction } from '../../actions/tabsBus.actions';
import { Button } from '../UIKit/components/Button/Button.component';

type TModelLinkDialogProps = WrappedComponentProps & {
    open: boolean;
    status: OpenTabStatus;
};

const ModelLinkDialog: React.FC<TModelLinkDialogProps> = ({ intl, status, open }) => {
    const dispatch = useDispatch();
    const clickHandler = () => {
        dispatch(tabsBusAppExitRequestAction());
        dispatch(closeDialog(DialogType.MODEL_LINK_DIALOG));
    };

    const hasFooter = status === OpenTabStatus.SUCCESS || status === OpenTabStatus.HAVE_AUTH_TAB;

    const footer = [
        <Button onClick={clickHandler} visualStyle="primary" size="large">
            {status === OpenTabStatus.SUCCESS ? intl.formatMessage(messages.openInCurrentClient) : intl.formatMessage(messages.startInCurrentClient)}
        </Button>
    ];

    return (
        <Dialog
            open={open}
            footer={hasFooter && footer}
            closable={false}
        >
            {status === OpenTabStatus.SUCCESS && (
                <div className={theme.about}>
                    <h3 className={theme.title}>{intl.formatMessage(messages.modelLinkDialogTitle)}</h3>
                    <p className={theme.paragraph}>{intl.formatMessage(messages.modelLinkDialogMessage1)}</p>
                    <p className={theme.paragraph}>{intl.formatMessage(messages.modelLinkDialogMessage2)}</p>
                </div>
            )}
            {status === OpenTabStatus.FAIL && (
                <div className={theme.about}>
                    <h3 className={theme.title}>{intl.formatMessage(messages.modelLinkDialogTitle)}</h3>
                    <p className={theme.redparagraph}>{intl.formatMessage(messages.modelLinkNoAccess)}</p>
                </div>
            )}
            {status === OpenTabStatus.HAVE_AUTH_TAB && (
                <div className={theme.about}>
                    <h3 className={theme.title}>{intl.formatMessage(messages.modelLinkDialogTitle)}</h3>
                    <p className={theme.paragraph}>{intl.formatMessage(messages.appHaveAuthTabDialogMessage1)}</p>
                    <p className={theme.paragraph}>{intl.formatMessage(messages.appHaveAuthTabDialogMessage2)}</p>
                </div>
            )}
        </Dialog>
    );
};

const ModelLinkDialogIntl = injectIntl(ModelLinkDialog);

export { ModelLinkDialogIntl as ModelLinkDialog };
