import { HeaderType } from '@/modules/Matrix/NewMatrixEditor/NewMatrix.types';
import {
    MATRIX_SELECT_HEADER_CELLS,
    MATRIX_CLEAR_SELECTED_CELLS,
    MATRIX_SELECT_CELLS,
    MATRIX_PAST_OBJECTS,
    NEW_MATRIX_SAVE_REQUEST,
    REFRESH_NEW_MATRIX,
    NEW_MATRIX_GET_EDGES_LIST,
    MATRIX_ADD_CELL_DATA,
    MATRIX_DELETE_EDGE_DEFINITION,
    MATRIX_START_LOADING_CELL_DATA,
    MATRIX_HANDLE_DECOMPOSITION_CLICK,
    DELETE_OBJECT_HEADERS,
} from '@/actionsTypes/entities/newMatrix.actionTypes';

import {
    TDeleteMatrixObjectHeaders,
    TMatrixAddCellDataAction,
    TMatrixClearSelectedCellsAction,
    TMatrixDecompositionIconClickAction,
    TMatrixDeleteEdgeDefinitionAction,
    TMatrixPastObjectsAction,
    TMatrixSelectCellsAction,
    TMatrixSelectHeaderCellsAction,
    TMatrixStartLoadingCellDataAction,
    TNewMatrixGetEdgesListAction,
    TNewMatrixSaveRequestAction,
    TRefreshNewMatrixAction,
} from './newMatrix.actions.types';
import { ModelAssignment, Node, NodeId } from '@/serverapi/api';
import { TMatrixCellData } from '@/reducers/entities/newMatrix.reducer.types';

export const matrixSelectHeaderCells = (
    nodeId: NodeId,
    type: HeaderType,
    ids: string[],
): TMatrixSelectHeaderCellsAction => ({
    type: MATRIX_SELECT_HEADER_CELLS,
    payload: {
        nodeId,
        type,
        ids,
    },
});

export const matrixSelectCells = (nodeId: NodeId, ids: string[]): TMatrixSelectCellsAction => ({
    type: MATRIX_SELECT_CELLS,
    payload: {
        nodeId,
        ids,
    },
});

export const matrixClearSelectedCells = (nodeId: NodeId): TMatrixClearSelectedCellsAction => ({
    type: MATRIX_CLEAR_SELECTED_CELLS,
    payload: {
        nodeId,
    },
});

export const matrixPastObjects = (nodeId: NodeId): TMatrixPastObjectsAction => ({
    type: MATRIX_PAST_OBJECTS,
    payload: {
        nodeId,
    },
});

export const newMatrixSaveRequest = (nodeId: NodeId): TNewMatrixSaveRequestAction => ({
    type: NEW_MATRIX_SAVE_REQUEST,
    payload: {
        nodeId,
    },
});

export const refreshNewMatrix = (nodeId: NodeId): TRefreshNewMatrixAction => ({
    type: REFRESH_NEW_MATRIX,
    payload: {
        nodeId,
    },
});

export const newMatrixGetEdgesList = (
    nodeId: NodeId,
    cellId: string,
    sourceObjectDefinitionId?: string,
    targetObjectDefinitionId?: string,
): TNewMatrixGetEdgesListAction => ({
    type: NEW_MATRIX_GET_EDGES_LIST,
    payload: {
        nodeId,
        cellId,
        sourceObjectDefinitionId,
        targetObjectDefinitionId,
    },
});

export const matrixStartLoadingCellData = (nodeId: NodeId, cellId: string): TMatrixStartLoadingCellDataAction => ({
    type: MATRIX_START_LOADING_CELL_DATA,
    payload: {
        nodeId,
        cellId,
    },
});

export const matrixAddCellData = (
    nodeId: NodeId,
    cellId: string,
    cellData: TMatrixCellData,
): TMatrixAddCellDataAction => ({
    type: MATRIX_ADD_CELL_DATA,
    payload: {
        nodeId,
        cellId,
        cellData,
    },
});

export const matrixDeleteEdgeDefinition = (
    matrixNodeId: NodeId,
    deleteNodeId: NodeId,
    cellId: string,
    sourceObjectDefinitionId?: string,
    targetObjectDefinitionId?: string,
): TMatrixDeleteEdgeDefinitionAction => ({
    type: MATRIX_DELETE_EDGE_DEFINITION,
    payload: {
        matrixNodeId,
        deleteNodeId,
        cellId,
        sourceObjectDefinitionId,
        targetObjectDefinitionId,
    },
});

export const matrixDecompositionIconClick = (
    nodeId: NodeId,
    edgeDefinitionId: string,
    modelAssignments: ModelAssignment[],
): TMatrixDecompositionIconClickAction => ({
    type: MATRIX_HANDLE_DECOMPOSITION_CLICK,
    payload: {
        nodeId,
        edgeDefinitionId,
        modelAssignments,
    },
});

export const deleteMatrixObjectHeaders = (nodes: Node[]): TDeleteMatrixObjectHeaders => ({
    type: DELETE_OBJECT_HEADERS,
    payload: {
        nodes,
    },
});
