import React, { FC, useEffect, useState } from 'react';
import theme from './NewMatrixEditor.scss';
import cn from 'classnames';
import { Table } from 'semantic-ui-react';
import { TSelectedHeadersCells } from '@/reducers/entities/newMatrix.reducer.types';
import { MatrixCellBPM8764, MatrixDataBPM8764, MatrixLane, NodeId } from '@/serverapi/api';
import { fillCellsWithSameLinkedNodeIds, renderCellSymbol } from '../NewMatrix.utils';
import { matrixClearSelectedCells, matrixSelectCells } from '@/actions/entities/newMatrix.actions';
import { useDispatch } from 'react-redux';
import { NewMatrixContextMenu } from './NewMatrixContextMenu/NewMatrixContextMenu.component';
import { useContextMenuDublicateDelete } from '@/hooks/useContextMenuDublicateDelete';
import { MenuInfo } from 'rc-menu/es/interface';
import messages from './NewMatrixContextMenu/NewMatrixContextMenu.messages';
import { CellsContextActions } from './NewMatrix.types';
import { getMenuItem } from '@/utils/antdMenuItem.utils';
import { useIntl } from 'react-intl';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { Locale } from '@/modules/Header/components/Header/header.types';
import { Tooltip } from 'antd';
import { LocalesService } from '@/services/LocalesService';
import { EllipsisOutlined } from '@ant-design/icons';
import { CELL_ICON_WIDTH, CELL_TEXT_WIDTH } from '../NewMatrix.constants';
import { TEdgesListDialogProps } from './NewMatrixEdgesListDialog/NewMatrixEdgesListDialog.types';
import { showNotification } from '../../../actions/notification.actions';
import { NotificationType } from '../../../models/notificationType';
import { v4 as uuid } from 'uuid';

type TNewMatrixCellProps = {
    nodeId: NodeId;
    cellId: string;
    rowId: string;
    colId: string;
    rowIndex: number;
    colIndex: number;
    width: number;
    isReadMode: boolean;
    isCellSelectable: boolean;
    selectedCells: string[];
    cell: MatrixCellBPM8764;
    selectedHeaderCells: TSelectedHeadersCells;
    rowsHeaders: MatrixLane[];
    colsHeaders: MatrixLane[];
    matrixData: MatrixDataBPM8764;
    currentLocale: Locale;
    rowIcon?: string;
    colIcon?: string;
    updateMatrixData: (data: MatrixDataBPM8764) => void;
    showItem: boolean;
    isCellForbiddenToEdit: boolean;
};

export const NewMatrixCell: FC<TNewMatrixCellProps> = ({
    nodeId,
    cellId,
    colId,
    rowId,
    cell,
    colIndex,
    rowIndex,
    rowIcon,
    colIcon,
    width,
    isReadMode,
    isCellSelectable,
    colsHeaders,
    rowsHeaders,
    selectedCells,
    selectedHeaderCells,
    matrixData,
    currentLocale,
    showItem,
    isCellForbiddenToEdit,
    updateMatrixData,
}) => {
    const [isContextMenuVisible, setContextMenuVisible] = useContextMenuDublicateDelete('matrix', {
        ...nodeId,
        id: cellId,
    });

    const [symbolsWidth, setSymbolsWidth] = useState<number>(0);

    useEffect(() => {
        if (!matrixData?.cellSettings?.styles || matrixData.cellSettings.styles.length === 0) return;

        const cellStyles = matrixData.cellSettings.styles.filter((style) => cell.styleIds.includes(style.id));

        let newSymbolsWidth = 0;
        cellStyles.forEach((style) => {
            if (style.type === 'ICON' || style.type === 'USER_ICON') {
                newSymbolsWidth += CELL_ICON_WIDTH;
            } else {
                newSymbolsWidth += CELL_TEXT_WIDTH;
            }
        });
        setSymbolsWidth(newSymbolsWidth);
    }, [matrixData.cellSettings.styles, cell.styleIds]);

    const isCompact: boolean = symbolsWidth > width;
    const isCellSelected: boolean = selectedCells.includes(cellId);
    const isAutomaticCellFillMode: boolean = !!matrixData.cellSettings.isAutomatic;
    const currentRow = rowsHeaders[rowIndex];
    const currentCol = colsHeaders[colIndex];
    const colName = LocalesService.internationalStringToString(currentCol.text, currentLocale);
    const rowName = LocalesService.internationalStringToString(currentRow.text, currentLocale);

    const intl = useIntl();
    const dispatch = useDispatch();

    const clearCellsSelectionAction = () => {
        dispatch(matrixClearSelectedCells(nodeId));
    };

    const selectCellsAction = (ids: string[]) => {
        dispatch(matrixSelectCells(nodeId, ids));
    };

    const hideContextMenu = () => {
        setContextMenuVisible(false);
    };

    const setStylesForCell = () => {
        if (!matrixData?.cellSettings?.styles?.length || !cell || isAutomaticCellFillMode) return;

        const { styles } = matrixData.cellSettings;

        if (cell.styleIds.length === 0) {
            cell.styleIds = [styles[0].id];
        } else {
            const currentStyleIndex = styles.findIndex((style) => style.id === cell.styleIds[0]);
            const newStyleIndex = currentStyleIndex + 1;
            if (cell.styleIds.length > 1 || newStyleIndex > styles.length - 1) {
                cell.styleIds = [];
            } else {
                const newStyleId = styles[newStyleIndex].id;
                cell.styleIds = [newStyleId];
            }
        }

        fillCellsWithSameLinkedNodeIds(cell, matrixData);

        updateMatrixData(matrixData);
    };

    const cellSelectHandlet = () => {
        selectCellsAction([cellId]);
    };

    const onCellClickHandler = () => {
        if (isReadMode) return;

        if (isCellForbiddenToEdit) {
            dispatch(
                showNotification({
                    id: uuid(),
                    type: NotificationType.ACCESS_DENIED_BY_PROFILE,
                }),
            );

            return;
        }

        if (selectedHeaderCells.ids.length !== 0) {
            clearCellsSelectionAction();
        }

        if (!isCellSelected) {
            cellSelectHandlet();
        } else {
            setStylesForCell();
        }
    };

    const EllipsisSymbol = () => (
        <EllipsisOutlined
            style={{
                maxWidth: '20px',
                maxHeight: '20px',
                minWidth: '20px',
                minHeight: '20px',
            }}
        />
    );

    const getRenderSymbolForCell = () => {
        if (!matrixData?.cellSettings?.styles || matrixData.cellSettings.styles.length === 0 || !showItem) return;

        const cellStyles = matrixData.cellSettings.styles.filter((style) => cell.styleIds.includes(style.id));
        let containerWidth = width;

        return (
            <div className={cn(theme.cellSymbolContainer, { [theme.compact]: isCompact })}>
                {cellStyles.map((style) => {
                    const { symbol, color, description } = renderCellSymbol(style, theme.antdIconsContainer);

                    const isLastVisibleSymbol = containerWidth < 0;

                    if (style.type === 'ICON' || style.type === 'USER_ICON') {
                        containerWidth -= CELL_ICON_WIDTH;
                    } else {
                        containerWidth -= CELL_TEXT_WIDTH;
                    }

                    const isSymbolIntoBounds = containerWidth > 0;

                    if (isLastVisibleSymbol) return '';

                    if (cell.styleIds.length === 1 && description) {
                        const localeDescription = LocalesService.internationalStringToString(
                            description,
                            currentLocale,
                        );

                        return (
                            <Tooltip key={`${cellId}_${style.id}`} title={localeDescription}>
                                <div style={{ color }}>{isSymbolIntoBounds ? symbol : <EllipsisSymbol />}</div>
                            </Tooltip>
                        );
                    }

                    return (
                        <div key={`${cellId}_${style.id}`} style={{ color, display: 'flex' }}>
                            {isSymbolIntoBounds ? symbol : <EllipsisSymbol />}
                        </div>
                    );
                })}
            </div>
        );
    };

    const openContextMenu = (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
        event.preventDefault();

        if (isCellSelectable) {
            cellSelectHandlet();
            setContextMenuVisible(true);
        }
    };

    const getContextMenu = (): ItemType[] => {
        if (isAutomaticCellFillMode) {
            return [
                getMenuItem(
                    intl.formatMessage(messages[CellsContextActions.createDefinition]),
                    CellsContextActions.createDefinition,
                    !currentCol.linkedNodeId || !currentRow.linkedNodeId,
                ),
                getMenuItem(
                    intl.formatMessage(messages[CellsContextActions.edgesList]),
                    CellsContextActions.edgesList,
                    !currentCol.linkedNodeId || !currentRow.linkedNodeId || cell.styleIds.length === 0,
                ),
            ];
        }

        return [
            getMenuItem(
                intl.formatMessage(messages[CellsContextActions.symbolsSettings]),
                CellsContextActions.symbolsSettings,
                !isCellSelectable,
            ),
        ];
    };

    const handleMenuAction = (event: MenuInfo) => {
        switch (event.key) {
            case CellsContextActions.symbolsSettings:
                dispatch(
                    openDialog(DialogType.NEW_MATRIX_CELL_SYMBOLS_SETTINGS_DIALOG, {
                        nodeId,
                        selectedStyleIds: cell.styleIds,
                        cellId,
                    }),
                );
                break;
            case CellsContextActions.createDefinition:
                dispatch(
                    openDialog(DialogType.NEW_MATRIX_CREATE_DEFINITION_DIALOG, {
                        nodeId,
                        currentRow,
                        currentCol,
                        rowIcon,
                        colIcon,
                    }),
                );
                break;

            case CellsContextActions.edgesList:
                {
                    const dialogProps: TEdgesListDialogProps = {
                        open: true,
                        nodeId,
                        cellId,
                        sourceObjectDefinitionId: currentRow.linkedNodeId,
                        sourceName: rowName,
                        targetObjectDefinitionId: currentCol.linkedNodeId,
                        targetName: colName,
                        cellStyles: matrixData.cellSettings.styles || [],
                    };

                    dispatch(openDialog(DialogType.NEW_MATRIX_EDGES_LIST_DIALOG, dialogProps));
                }
                break;

            default:
                break;
        }
    };

    return (
        <>
            <Table.Cell
                onClick={() => {
                    if (isCellSelectable) onCellClickHandler();
                }}
                onContextMenu={openContextMenu}
                key={cellId}
                className={cn(theme.tableCell, {
                    [theme.selected]:
                        selectedHeaderCells.ids.some(
                            (selectedCellId) => selectedCellId === rowId || selectedCellId === colId,
                        ) || isCellSelected,
                    [theme.bottomBorder]:
                        rowsHeaders.findLastIndex((row) => row.text) === rowIndex &&
                        colsHeaders.findLastIndex((col) => col.text) >= colIndex,
                    [theme.rightBorder]:
                        rowsHeaders.findLastIndex((row) => row.text) >= rowIndex &&
                        colsHeaders.findLastIndex((col) => col.text) === colIndex,
                    [theme.pointer]: isCellSelectable,
                })}
                style={{
                    maxWidth: `${width}px`,
                    minWidth: `${width}px`,
                }}
            >
                {isContextMenuVisible && (
                    <NewMatrixContextMenu
                        visible={isContextMenuVisible}
                        menuItems={getContextMenu()}
                        hideContextMenu={hideContextMenu}
                        handleMenuAction={handleMenuAction}
                    />
                )}
                {getRenderSymbolForCell()}
            </Table.Cell>
            <div className={cn(theme.colResizer, theme.defaultCursor)} />
        </>
    );
};
