import React from 'react';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { Button, Form } from 'antd';
import { ParameterType } from '../../../../reducers/scriptExecuteDialog.reducer.types';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import messages from './ScriptExecuteDialog.messages';
import theme from './ScriptExecuteDialog.scss';
import { ScriptExecuteForm } from './ScriptExecuteForm.component';
import { TScriptExecuteDialogAllProps } from './ScriptExecuteDialog.types';
import { AttributeValueQuery } from '../../../../serverapi/api';

export const ScriptExecuteDialog = (props: TScriptExecuteDialogAllProps) => {
    const intl = useIntl();
    const { onClose, onSubmit, onPreviousStep, parsedParams, fileParams, nodeParams, scriptId, existingParams, step, confirmText, cancelText, previousOperationId, operationId } = props;
    const [form] = Form.useForm();

    const handleSubmit = () => {
        if (form) {
            form.validateFields()
                .then((formValues) => {
                    form.resetFields();
                    parsedParams.filter(p => !p.hide).forEach((param) => {
                        switch (param.paramType) {
                            case ParameterType.FILE:
                                param.value = fileParams[param.name || '']?.uploadedFileName;
                                break;
                            case ParameterType.BOOLEAN:
                                param.value =
                                    formValues[param.name || '']?.toString().toLowerCase() === 'true'
                                        ? 'true'
                                        : 'false';
                                break;
                            case ParameterType.DATE:
                                const value = dayjs(formValues[param.name || ''] as string).unix();
                                param.value = value ? value.toString() : '';
                                break;
                            case ParameterType.NODE:
                                const nodeId = nodeParams[param.name || '']?.nodeId;
                                param.value = nodeId ? JSON.stringify(nodeId) : '';
                                break;
                            case ParameterType.QUERY_SELECT:
                                param.value =
                                    (formValues[param.name || ''] as AttributeValueQuery[] | undefined)?.[0]?.id || '';
                                break;
                            case ParameterType.QUERY_MULTI_SELECT:
                                param.value = (formValues[param.name || ''] as AttributeValueQuery[] | undefined)
                                    ? JSON.stringify(
                                          (formValues[param.name || ''] as AttributeValueQuery[]).map(
                                              (queryAttributeValue) => queryAttributeValue.id,
                                          ),
                                      )
                                    : '';
                                break;
                            default:
                                param.value = (formValues[param.name || ''] || '').toString();
                                break;
                        }
                    });
                    onSubmit(scriptId, [...existingParams, ...parsedParams], operationId);
                })
                .catch((b) => b);
        }
    };
    const footer = (
        <div className={theme.footer}>
            <Button data-test="button_script-start_cancel" onClick={() => onClose(fileParams)}>
                {cancelText || intl.formatMessage(messages.scriptFormDeclineButton)}
            </Button>
            {!!previousOperationId &&
                <Button data-test="button_script-start_prev" onClick={() => onPreviousStep(previousOperationId, fileParams, scriptId.serverId)}>
                    {intl.formatMessage(messages.scriptFormBackButton)}
                </Button>}
            {!!step &&
                <Button data-test="button_script-start_confirm" type="primary" onClick={handleSubmit}>
                    {confirmText || intl.formatMessage(messages.scriptFormConfirmButtonStart)}
                </Button>}
        </div>
    );

    return (
        <Dialog
            maskClosable={false}
            className={theme.dialog}
            onCancel={() => onClose(fileParams)}
            title={props.title || intl.formatMessage(messages.scriptTitle)}
            open
            width="640px"
            footer={footer}
            data-test="script-execution-dialog"
        >
            <ScriptExecuteForm {...props} form={form} />
        </Dialog>
    );
};
