import { TReducer } from '../../utils/types';
import {
    MATRIX_REQUEST_SUCCESS,
    MATRIX_SAVE_REQUEST_SUCCESS,
    CHANGE_MATRIX_PROPERTIES,
    UPDATE_MATRIX_DATA,
} from '../../actionsTypes/entities/matrix.actionTypes';
import { TNodeState } from './TNodeState';
import { MatrixNode } from '../../serverapi/api';
import { TMatrixState } from './matrix.reducer.types';
import { checkIsNewMatrix } from '@/modules/Matrix/NewMatrix.utils';

const INITIAL_MATRIX_STATE: TMatrixState = new TNodeState();

export const matrixReducer: TReducer<TMatrixState> = (state = INITIAL_MATRIX_STATE, action) => {
    switch (action.type) {
        case MATRIX_SAVE_REQUEST_SUCCESS:
        case MATRIX_REQUEST_SUCCESS: {
            const {
                payload: { matrix },
            } = action;
            const nodeId = matrix.nodeId;

            const existMatrix: MatrixNode | undefined = state.byNodeId.get(nodeId);
            if (existMatrix) {
                if (checkIsNewMatrix(matrix) && existMatrix.version && matrix.version) {
                    const version = Math.max(existMatrix.version, matrix.version);

                    return state.set(nodeId, { ...matrix, version });
                }

                return state.set(nodeId, { ...existMatrix, ...matrix });
            }

            return state.set(matrix.nodeId, matrix);
        }

        case CHANGE_MATRIX_PROPERTIES: {
            const {
                payload: { matrix, nodeId },
            } = action;
            const existMatrix: MatrixNode | undefined = state.byNodeId.get(nodeId);
            if (existMatrix) {
                if (checkIsNewMatrix(matrix) && existMatrix.version && matrix.version) {
                    const version = Math.max(existMatrix.version, matrix.version);

                    return state.set(nodeId, { ...matrix, version });
                }

                return state.set(nodeId, { ...existMatrix, ...matrix });
            }

            return state.set(nodeId, { ...matrix, nodeId });
        }

        case UPDATE_MATRIX_DATA: {
            const {
                payload: { nodeId, data },
            } = action;
            const existMatrix: MatrixNode | undefined = state.byNodeId.get(nodeId);
            if (existMatrix) {
                return state.set(nodeId, { ...existMatrix, data2: data });
            }

            return state;
        }

        default:
            return state;
    }
};
