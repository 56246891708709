import { call, put, select, takeEvery } from 'redux-saga/effects';
import { TreeItemType } from '../modules/Tree/models/tree';
import {
    changeMatrixProperties,
    matrixChangeDataRequest,
    matrixSaveRequestFailure,
    matrixSaveRequestSuccess,
} from '../actions/entities/matrix.actions';
import { getShowDeletedObjectsFilter, getTreeItems, TreeSelectors } from '../selectors/tree.selectors';
import { v4 as uuid } from 'uuid';
import { IMatrixNode } from '../models/bpm/bpm-model-impl.types';
import { TTreeEntityState } from '../models/tree.types';
import { MatrixSelectors } from '../selectors/entities/matrix.selectors';
import { showNotification, showNotificationByType } from '../actions/notification.actions';
import {
    DiagramElement,
    EdgeDefinitionNode,
    EdgeType,
    MatrixDataBPM8764,
    MatrixLane,
    MatrixNode,
    Node,
    NodeId,
    ObjectConnection,
    ObjectInstance,
} from '../serverapi/api';
import { NotificationType } from '../models/notificationType';
import { getCopiedElements, getWhereCopiedFrom } from '@/selectors/editor.selectors';
import { cloneDeep } from 'lodash-es';
import { TEdgeIdWithType, HeaderType } from '@/modules/Matrix/NewMatrixEditor/NewMatrix.types';
import {
    MATRIX_DELETE_EDGE_DEFINITION,
    MATRIX_HANDLE_DECOMPOSITION_CLICK,
    DELETE_OBJECT_HEADERS,
    MATRIX_PAST_OBJECTS,
    NEW_MATRIX_GET_EDGES_LIST,
    NEW_MATRIX_SAVE_REQUEST,
    REFRESH_NEW_MATRIX,
} from '@/actionsTypes/entities/newMatrix.actionTypes';
import { NewMatrixSelectors } from '@/selectors/entities/newMatrix.selectors';
import { TSelectedHeadersCells } from '@/reducers/entities/newMatrix.reducer.types';
import {
    TDeleteMatrixObjectHeaders,
    TMatrixDecompositionIconClickAction,
    TMatrixDeleteEdgeDefinitionAction,
    TMatrixPastObjectsAction,
    TNewMatrixGetEdgesListAction,
    TNewMatrixSaveRequestAction,
} from '@/actions/entities/newMatrix.actions.types';
import { setServerIdToNodeOriginal } from '@/utils/nodeId.utils';
import { MatrixDaoService } from '@/services/dao/MatrixDaoService';
import { newMatrixSaveRequest } from '@/actions/entities/newMatrix.actions';
import { getLockingTool, SaveModelLockTool } from '@/modules/Editor/classes/SaveModelLockTool';
import { EdgeDefinitionDAOService } from '@/services/dao/EdgeDefinitionDAOService';
import { ObjectDefinitionImpl } from '@/models/bpm/bpm-model-impl';
import { nodeService } from '@/services/NodeService';
import { getConnectedEdgeInstances } from '@/modules/Matrix/NewMatrix.utils';
import { EdgeTypeSelectors } from '@/selectors/edgeType.selectors';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { LocalesService } from '@/services/LocalesService';
import {
    matrixAddCellData,
    matrixStartLoadingCellData,
    newMatrixGetEdgesList,
} from '@/actions/entities/newMatrix.actions';
import { treeItemDelete, treeItemRefresh } from '@/actions/tree.actions';
import { TreeDaoService } from '@/services/dao/TreeDaoService';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { openDialog } from '@/actions/dialogs.actions';
import { objectDecompositionOpen } from '@/actions/entities/objectDecomposition.actions';
import { TDecompositionsListDialogOwnProps } from '@/modules/Models/components/DecompositionsList/DecompositionsListDialog.types';
import { EdgeDefinitionSelectors } from '@/selectors/edgeDefinition.selector';
import { edgeDefinitionsAdd } from '@/actions/entities/edgeDefinition.actions';
import { clearStateAction } from '@/actions/undoRedo.actions';
import { TabsSelectors } from '../selectors/tabs.selectors';
import { batchActions } from '../actions/rootReducer.actions';
import { deleteMatrixHeadersByObjectDefinitions } from './utils/matrix.saga.utils';
import { TWorkspaceTab } from '../models/tab.types';
import { EditorMode } from '../models/editorMode';

function* handleMatrixPastObjects({ payload: { nodeId } }: TMatrixPastObjectsAction) {
    const matrix: IMatrixNode = yield select(MatrixSelectors.byId(nodeId));
    const selectedHeaderCells: TSelectedHeadersCells = yield select(NewMatrixSelectors.getSelectedHeaderCells(nodeId));
    const { ids: selectedCellsId, type: headerType } = selectedHeaderCells;
    const copiedElements = yield select(getCopiedElements);
    const whereCopiedFrom = yield select(getWhereCopiedFrom);
    const objectDefinitions: TTreeEntityState[] = yield select(getTreeItems(nodeId.serverId, nodeId.repositoryId));

    const matrixData: MatrixDataBPM8764 | undefined = cloneDeep(matrix.data2);

    if (!matrixData) return;

    const colsHeaders = matrixData.columns;
    const rowsHeaders = matrixData.rows;

    if (
        selectedCellsId.length > 0 &&
        whereCopiedFrom &&
        whereCopiedFrom.repositoryId === nodeId.repositoryId &&
        whereCopiedFrom.serverId === nodeId.serverId
    ) {
        const objectsToAdd: DiagramElement[] = copiedElements.filter((elem) => elem.type === 'object');

        const currentLanes = headerType === HeaderType.column ? [...colsHeaders] : [...rowsHeaders];
        let minIndex = currentLanes.length - 1;
        selectedCellsId.forEach((cellId) => {
            const laneIndex = currentLanes.findIndex((lane) => lane.id === cellId);
            minIndex = Math.min(minIndex, laneIndex);
        });

        const parentId: string | undefined = currentLanes[minIndex]?.parentId;

        const objectDefinitionsToAdd: TTreeEntityState[] = objectsToAdd.map((object) => {
            return objectDefinitions[(object as ObjectInstance).objectDefinitionId || ''];
        });

        const checkObjectDefinition = (objectDefinition: TTreeEntityState) => {
            return (
                !objectDefinition ||
                objectDefinition.type !== TreeItemType.ObjectDefinition ||
                objectDefinition.nodeId.repositoryId !== nodeId.repositoryId ||
                objectDefinition.nodeId.serverId !== nodeId.serverId
            );
        };

        const wrongObjectDefinition = objectDefinitionsToAdd.find((objectDefinition) =>
            checkObjectDefinition(objectDefinition),
        );
        if (wrongObjectDefinition) {
            yield put(
                showNotification({
                    id: uuid(),
                    type: NotificationType.DND_ERROR_WRONG_NODE_TYPE,
                    data: wrongObjectDefinition.type,
                }),
            );

            return;
        }

        const newMatrixLanes: MatrixLane[] = objectDefinitionsToAdd.map((objectDefinition) => {
            return {
                id: uuid(),
                linkedNodeId: objectDefinition.nodeId.id,
                symbolId: objectDefinition.idSymbol,
                text: objectDefinition.multilingualName,
                parentId,
            };
        });
        currentLanes.splice(minIndex, 0, ...newMatrixLanes);
        if (headerType === HeaderType.column) {
            matrixData.columns = currentLanes;
        }
        if (headerType === HeaderType.row) {
            matrixData.rows = currentLanes;
        }

        yield put(matrixChangeDataRequest(nodeId, undefined, matrixData));
    }
}

function* handleNewMatrixSaveRequest(action: TNewMatrixSaveRequestAction) {
    const { nodeId } = action.payload;

    const isMatrixUnsaved: IMatrixNode = yield select(NewMatrixSelectors.isMatrixUnsaved(nodeId));
    if (!isMatrixUnsaved) return;

    const matrix: IMatrixNode | undefined = yield select(MatrixSelectors.byId(nodeId));

    if (matrix) {
        const lock: SaveModelLockTool = getLockingTool();

        lock.addLock(nodeId.id);
        try {
            const data: IMatrixNode = yield MatrixDaoService.saveMatrix(matrix as IMatrixNode);
            yield put(matrixSaveRequestSuccess(data));
        } catch (e) {
            yield put(matrixSaveRequestFailure(nodeId.serverId, e.message));
            throw e;
        } finally {
            lock.deleteLock(nodeId.id);
        }
    }
}

function* handleRefreshNewMatrix(action: TNewMatrixSaveRequestAction) {
    const { nodeId } = action.payload;

    const lock: SaveModelLockTool = getLockingTool();
    if (lock.isLocked(nodeId.id)) {
        yield put(showNotificationByType(NotificationType.MATRIX_IS_SAVING));
        return;
    }

    try {
        yield handleNewMatrixSaveRequest(newMatrixSaveRequest(nodeId));

        const matrix: IMatrixNode = yield MatrixDaoService.getMatrix(nodeId.repositoryId, nodeId.id);
        setServerIdToNodeOriginal(matrix, nodeId.serverId);
        yield put(matrixSaveRequestSuccess(matrix));
        yield put(clearStateAction(nodeId));
    } catch (e) {
        yield put(matrixSaveRequestFailure(nodeId.serverId, e.message));
        throw e;
    }
}

function* handleNewMatrixGetEdgesList(action: TNewMatrixGetEdgesListAction) {
    const { nodeId, cellId, sourceObjectDefinitionId, targetObjectDefinitionId } = action.payload;

    if (!sourceObjectDefinitionId || !targetObjectDefinitionId) return;

    let edgeDefinitionIdsWithEdgeTypeNames: TEdgeIdWithType[] = [];
    let edgeInstanceIdsWithEdgeTypeNames: TEdgeIdWithType[] = [];

    try {
        yield put(matrixStartLoadingCellData(nodeId, cellId));

        const currentLocale = yield select(getCurrentLocale);
        const presetId = yield select(TreeSelectors.presetById(nodeId));
        const edgeTypes: EdgeType[] = yield select(EdgeTypeSelectors.listByPresetId(nodeId.serverId, presetId));

        const edgeDefinitions: EdgeDefinitionNode[] = yield EdgeDefinitionDAOService.searchExistingEdgeDefinitions(
            nodeId,
            [sourceObjectDefinitionId],
            [targetObjectDefinitionId],
        );

        let reverseEdgeDefinitions: EdgeDefinitionNode[] = [];

        if (sourceObjectDefinitionId !== targetObjectDefinitionId) {
            reverseEdgeDefinitions = yield EdgeDefinitionDAOService.searchExistingEdgeDefinitions(
                nodeId,
                [targetObjectDefinitionId],
                [sourceObjectDefinitionId],
            );
        }

        const allEdgeDefinitions = [...edgeDefinitions, ...reverseEdgeDefinitions].map((edgeDefinition) => {
            return {
                ...edgeDefinition,
                nodeId: { ...edgeDefinition.nodeId, serverId: nodeId.serverId },
            };
        });

        yield put(edgeDefinitionsAdd(allEdgeDefinitions));

        edgeDefinitionIdsWithEdgeTypeNames = allEdgeDefinitions.map((edgeDefinition: EdgeDefinitionNode) => {
            const currentEdgeType: EdgeType | undefined = edgeTypes.find(
                (edgeType) => edgeType.id === edgeDefinition.edgeTypeId,
            );

            const edgeTypeName =
                LocalesService.internationalStringToString(currentEdgeType?.multilingualName, currentLocale) ||
                currentEdgeType?.name ||
                '';

            return {
                id: edgeDefinition.nodeId.id,
                edgeTypeName,
                edgeTypeId: currentEdgeType?.id || edgeDefinition.edgeTypeId || '',
                isEdgeInctance: false,
                isOutgoingEdge: edgeDefinition.nodeId.id === sourceObjectDefinitionId,
                modelAssignments: edgeDefinition.modelAssignments,
            };
        });

        const node: Node | undefined = yield nodeService().loadNodeFromServer({
            ...nodeId,
            id: sourceObjectDefinitionId,
        });
        const edgeInstances: ObjectConnection[] = getConnectedEdgeInstances(
            node as ObjectDefinitionImpl,
            targetObjectDefinitionId,
        );

        edgeInstanceIdsWithEdgeTypeNames = edgeInstances.map((edgeInstance: ObjectConnection) => {
            const currentEdgeType: EdgeType | undefined = edgeTypes.find(
                (edgeType) => edgeType.id === edgeInstance.edgeTypeId,
            );

            const edgeTypeName =
                LocalesService.internationalStringToString(currentEdgeType?.multilingualName, currentLocale) ||
                currentEdgeType?.name ||
                '';

            return {
                id: edgeInstance.edgeInstanceId || '',
                edgeTypeName,
                edgeTypeId: currentEdgeType?.id || edgeInstance.edgeTypeId || '',
                isEdgeInctance: true,
                isOutgoingEdge: !!edgeInstance.isOutgoingEdge,
            };
        });
    } finally {
        yield put(
            matrixAddCellData(nodeId, cellId, {
                edgeDefinitions: edgeDefinitionIdsWithEdgeTypeNames,
                edgeInstances: edgeInstanceIdsWithEdgeTypeNames,
            }),
        );
    }
}

function* handleDeleteEdgeDefinition(action: TMatrixDeleteEdgeDefinitionAction) {
    const { matrixNodeId, deleteNodeId, cellId, sourceObjectDefinitionId, targetObjectDefinitionId } = action.payload;
    const node: EdgeDefinitionNode | undefined = yield nodeService().loadNodeFromServer(deleteNodeId);

    if (!node) return;

    if (node.edgeEntries?.length !== 0) {
        yield put(showNotificationByType(NotificationType.EDGE_DEFINITION_HAS_ENTRIES));

        return;
    }
    yield call(() => TreeDaoService.delete(deleteNodeId));

    const showDeletedObjects: boolean = yield select(getShowDeletedObjectsFilter);

    if (!showDeletedObjects) {
        yield put(treeItemDelete(deleteNodeId));
    }
    yield put(treeItemRefresh(node.parentNodeId));
    yield put(newMatrixGetEdgesList(matrixNodeId, cellId, sourceObjectDefinitionId, targetObjectDefinitionId));
}

function* handleDecompositionIconClick({ payload }: TMatrixDecompositionIconClickAction) {
    const { nodeId, edgeDefinitionId, modelAssignments } = payload;
    let edgeDefinition: EdgeDefinitionNode | undefined;

    if (edgeDefinitionId) {
        const edgeNodeId = { ...nodeId, id: edgeDefinitionId } as NodeId;
        edgeDefinition = yield select(EdgeDefinitionSelectors.byId(edgeNodeId));
    }

    if (modelAssignments.length > 1) {
        const props: TDecompositionsListDialogOwnProps = { edgeDefinition, modelId: nodeId };

        yield put(openDialog(DialogType.DECOMPOSITIONS_LIST_DIALOG, props));
    } else if (modelAssignments.length === 1) {
        const modelAssignment = modelAssignments[0];

        if (modelAssignment?.modelId && modelAssignment?.nodeType) {
            yield put(
                objectDecompositionOpen({
                    nodeId: { ...nodeId, id: modelAssignment.modelId },
                    type: modelAssignment.nodeType as TreeItemType,
                }),
            );
        }
    }
}

function* handleDeleteMatrixObjectHeaders(action: TDeleteMatrixObjectHeaders) {
    const { nodes } = action.payload;

    const matrixTabs: TWorkspaceTab[] = yield select(TabsSelectors.getOpenMatrixTabs);
    const editingMatrixNodeIs: NodeId[] = matrixTabs
        .filter((tab) => tab.mode === EditorMode.Edit)
        .map((tab) => tab.nodeId);
    const matrixArr: MatrixNode[] = yield select(MatrixSelectors.byIds(editingMatrixNodeIs));

    const objectDefinitionIds: string[] = nodes
        .filter((node) => node.type === TreeItemType.ObjectDefinition)
        .map((definition) => definition.nodeId.id);
    const newMatrixArr: MatrixNode[] = deleteMatrixHeadersByObjectDefinitions(matrixArr, objectDefinitionIds);

    yield put(
        batchActions(
            newMatrixArr.map((matrix) => {
                return changeMatrixProperties(matrix.nodeId, matrix);
            }),
        ),
    );
}

export function* newMatrixSaga() {
    yield takeEvery(MATRIX_PAST_OBJECTS, handleMatrixPastObjects);
    yield takeEvery(NEW_MATRIX_SAVE_REQUEST, handleNewMatrixSaveRequest);
    yield takeEvery(REFRESH_NEW_MATRIX, handleRefreshNewMatrix);
    yield takeEvery(NEW_MATRIX_GET_EDGES_LIST, handleNewMatrixGetEdgesList);
    yield takeEvery(MATRIX_DELETE_EDGE_DEFINITION, handleDeleteEdgeDefinition);
    yield takeEvery(MATRIX_HANDLE_DECOMPOSITION_CLICK, handleDecompositionIconClick);
    yield takeEvery(DELETE_OBJECT_HEADERS, handleDeleteMatrixObjectHeaders);
}
