import { TTreeEntityState } from '../../models/tree.types';
import { checkIsNewMatrix, getSelectedLanesWithChildren } from '../../modules/Matrix/NewMatrix.utils';
import { MatrixHeader, MatrixLane, MatrixNode, NodeId, NodeMatrixHeader } from '../../serverapi/api';

export const getAbsentNodesId = (matrix: MatrixNode, treeItemsById: { [id: string]: TTreeEntityState }): NodeId[] => {
    // после удаление старой матричной модели удалить эту проверку,
    // оставить только первое значение (после ?), data2 переименовать в data
    // второе значение удалить
    // поправить тест matrix.saga.utils.test.ts
    const isNewMatrixModel: boolean = checkIsNewMatrix(matrix);

    const absentNodesId: NodeId[] = isNewMatrixModel
        ? [...(matrix.data2?.columns || []), ...(matrix.data2?.rows || [])].reduce(
              (summ: NodeId[], current: MatrixLane) => {
                  const id: string | undefined = current.linkedNodeId;
                  if (id) {
                      summ.push({ ...matrix.nodeId, id });
                  }

                  return summ;
              },
              [],
          )
        : [...(matrix.data?.columns || []), ...(matrix.data?.rows || [])].reduce(
              (summ: NodeId[], current: MatrixHeader) => {
                  if (current.type === 'NODE') {
                      const id = (current as NodeMatrixHeader).nodeId;
                      if (!treeItemsById[id]) {
                          summ.push({ ...matrix.nodeId, id });
                      }
                  }

                  return summ;
              },
              [],
          );

    return absentNodesId;
};

export const deleteMatrixHeadersByObjectDefinitions = (
    matrixArr: MatrixNode[],
    deletingObjectDefinitionIds: string[],
): MatrixNode[] => {
    const newMatrixArr: MatrixNode[] = matrixArr.map((matrix) => {
        const { data2: matrixData } = matrix;

        if (matrixData) {
            const { cells, rows: rowsHeaders, columns: colsHeaders } = matrixData;

            const deletingRowsIdWithChildren: string[] = getDeletingHeadersIdWithChildren(
                rowsHeaders,
                deletingObjectDefinitionIds,
            );

            const deletingColsIdWithChildren: string[] = getDeletingHeadersIdWithChildren(
                colsHeaders,
                deletingObjectDefinitionIds,
            );

            matrixData.columns = colsHeaders.map((col) => {
                if (deletingColsIdWithChildren.includes(col.id)) {
                    return {
                        ...col,
                        linkedNodeId: undefined,
                        symbolId: undefined,
                        text: undefined,
                        parentId: undefined,
                    };
                }

                return col;
            });

            matrixData.rows = rowsHeaders.map((row) => {
                if (deletingRowsIdWithChildren.includes(row.id)) {
                    return {
                        ...row,
                        linkedNodeId: undefined,
                        symbolId: undefined,
                        text: undefined,
                        parentId: undefined,
                    };
                }

                return row;
            });

            matrixData.cells = cells.map((cell) => {
                const { columnId, rowId } = cell;
                if (
                    deletingColsIdWithChildren.some((cellId) => cellId === columnId) ||
                    deletingRowsIdWithChildren.some((cellId) => cellId === rowId)
                )
                    return { columnId, rowId, styleIds: [] };

                return cell;
            });

            const newMatrix: MatrixNode = { ...matrix, data2: matrixData };

            return newMatrix;
        }

        return matrix;
    });

    return newMatrixArr;
};

export const getDeletingHeadersIdWithChildren = (headers: MatrixLane[], objectDefinitionIds: string[]): string[] => {
    const deletingHeaders: string[] = headers
        .filter((header) => header.linkedNodeId && objectDefinitionIds.includes(header.linkedNodeId))
        .map((header) => header.id);
    const deletingRowsWithChildren: string[] = getSelectedLanesWithChildren(deletingHeaders, headers, true).map(
        (header) => header.id,
    );

    return deletingRowsWithChildren;
};
