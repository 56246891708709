export const SHORT_NOTIFICATION_DURATION = 3;

export const MEDIUM_NOTIFICATION_DURATION = 6;

export const LONG_NOTIFICATION_DURATION = 30;

export enum NotificationType {
    LOW_SERVER,
    MODEL_RESTORE_VERSION_SUCCESS,
    MODEL_RESTORE_VERSION_ERROR,
    MODEL_REFRESH_SUCCESS,
    MODEL_REFRESH_ERROR,
    MODEL_TO_EDIT_NOT_FOUND,
    OBJECT_TO_EDIT_NOT_FOUND,
    NETWORK_ERROR,
    UNEXPECTED_JSON,
    SERVER_ERROR,
    COMMON_ERROR,
    FORBIDDEN_ERROR,
    UNAUTHORIZED_ERROR,
    CONNECTION_LOST_ERROR,
    INCORRECT_IMAGE_ERROR,
    NOT_SVG_IMAGE_ERROR,
    CONNECTION_OPENED_NOTIFICATION,
    SUBSCRIBE_DB_SUCCESS,
    UNSUBSCRIBE_DB_SUCCESS,
    STALE_GRAPH_NOTIFICATION,
    NEW_SCRIPT_STATUS_NOTIFICATION,
    SCRIPT_COMPILATION_ERROR,
    DIAGRAM_LOCK_ERROR,
    DIAGRAM_LOCK_EMPTY_MODEL_TYPE,
    OBJECT_LOCK_ERROR,
    PAYMENT_REQUIRED,
    FILE_DOWNLOADED,
    IMPORT_MODEL_TYPE_SUCCESS,
    IMPORT_FILE_SUCCESS,
    IMPORT_FILE_FAIL,
    GRID_OUTSIDE_DROP_ERROR,
    PSD_DELETE_FIRST_ROW_ERROR,
    PSD_DELETE_FIRST_COLUMN_ERROR,
    EPC_DELETE_EMPTY_COLUMN_ERROR,
    EPC_DELETE_LAST_COLUMN_ERROR,
    EPC_DELETE_LAST_ROW_ERROR,
    PSD_DROP_NOT_SUPPORTED,
    IMPORT_LICENSE_SUCCESS,
    IMPORT_LICENSE_FILE_CORRUPTED,
    IMPORT_LICENSE_LICENSE_EXIST,
    IMPORT_LICENSE_FAIL,
    COPY_FAIL,
    COPY_GUID_SUCCESS,
    COPY_GUID_FAIL,
    COPY_ITEM_LINK,
    COPY_ITEM_LINK_ERROR,
    SEARCH_TEXT_LESS,
    SYSTEM_PROPERTY_FAIL,
    REPEAT_EDGE_DEFINITION,
    NONE_EXISTING_EDGE_DEFINITION,
    SUCCESSFUL_IMPORT_VISIO,
    INVALID_IMPORT_VISIO,
    NOT_FOUND_ERROR,
    GOTO_ERROR,
    DUPLICATE_ENTITY,
    ACCESS_DENIED_BY_PROFILE,
    USER_LOGIN_CONFLICT,
    GROUP_ID_CONFLICT,
    PROFILE_CREATE_ERROR,
    INCORRECT_OBJECT_TYPE,
    INCORRECT_SYMBOL_TYPE,
    EXPORT_FAIL,
    DND_ERROR_INVALID_REPOSITORY,
    DND_ERROR_MODEL_IS_LOCKED,
    DND_ERROR_WRONG_NODE_TYPE,
    DND_ERROR_CANT_CREATE_DECOMPOSITION,
    DND_ERROR_CANT_MOVE_DELETED_NODE,
    ITEM_TYPE_IS_NOT_EXIST,
    MOVE_NODE_FAILED,
    MOVE_NODES_FORBIDDEN,
    SUCCESS_SAVING_SYSTEM_PROPERTY,
    EMPTY_CLIPBOARD,
    METHODOLOGY_IS_OPENED,
    INVALID_PARENT_FOR_ELEMENT_TYPE,
    INSERT_NODE_IN_FOLDER_TYPE,
    DND_ERROR_EXIST_IN_THIS_REPOSITORY,
    INVALID_OBJECT_TYPE_FOR_THIS_FOLDER,
    SELECTED_ELEMENTS_CANT_BE_MOVED,
    RESTART_TO_APPLY_CHANGES,
    ERROR_INVALID_ICON_FORMAT,
    CHOOSE_ICON_ERROR,
    SAVE_ARIS_IN_EXCEL_ERROR,
    LOAD_ARIS_FROM_EXCEL_ERROR,
    LOGIN_IS_EMPTY,
    ERASE_NODE,
    RESTORE_NODE,
    NODE_DELETED,
    UNSUPPORTED_OPERATION,
    PASSWORD_LENGTH_IS_INCORRECT,
    PASSWORD_NOT_CONTAIN_LATIN_LETTER,
    PASSWORD_CONTAINS_NOT_ONLY_LATIN_LETTERS,
    PASSWORD_NOT_CONTAIN_BOTH_UPPERCASE_AND_LOWERCASE_LETTERS,
    PASSWORD_NOT_CONTAIN_DIGITS,
    PASSWORD_NOT_CONTAIN_SPECIAL_CHARACTERS,
    NEW_PASSWORD_EQUAL_OLD_PASSWORD,
    PASSWORD_CHANGED_RECENTLY,
    DEFAULT_BAD_REQUEST,
    NO_AUTHOR_ADD_MARKER,
    LOGIN_CONTAINS_NOT_ONLY_LATIN_LETTERS,
    CHECK_LDAP_SERVER_ERROR,
    NODE_MISSING_PARENT,
    INVALID_REPOSITORY_ID,
    PARENT_AND_CHILD_ID_UNEQUAL,
    REPOSITORY_PARENT_ERROR,
    INCORRECT_RESOURCE_FOR_NODE,
    NO_USER_PROFILE_ASSIGNED,
    NODE_SUBSCRIBED,
    NODE_UNSUBSCRIBED,
    CANNOT_OPEN_SVG_ERROR,
    CANNOT_PASTE_FROM_ANOTHER_REPOSITORY,
    NODE_ADDED_TO_FAVORITES,
    NODE_REMOVED_FROM_FAVORITES,
    APPROVAL_NOT_ALLOWED,
    MAX_SIZE_FOR_FILE,
    CHECK_KEYCLOAK_SERVER_ERROR,
    ATTRIBUTE_FILTER_VALIDATE_ERROR,
    SCRIPT_ENGINE_IS_UNAVAILABLE,
    BPMN_DATA_WARNING,
    LIMITED_FILE_SIZE_CUSTOM_MAX,
    EDGE_IS_FORBIDDEN_TO_EDIT,
    THE_CURRENT_PASSWORD_IS_INCORRECT,
    CHANGE_USER_PASSWORD_SUCCESS,
    SYMBOLS_OR_EDGES_NOT_SUPPORTED_ON_MODEL_TYPE,
    NO_VALID_SYMBOL_ON_MODEL_TYPE,
    EDGES_OR_EDGE_BETWEEN_SYMBOLS_NOT_SUPPORTED_ON_MODEL_TYPE,
    PASSWORD_CHANGE_PROHIBITED,
    COPY_DATABASE_SUCCESS,
    SYMBOL_MOVE_DISABLED,
    PUBLICATION_NODE_MARK_DELETED,
    PUBLICATION_NODE_READ_ACCESS_DENIED,
    MATRIX_IS_SAVING,
    EDGE_DEFINITION_HAS_ENTRIES,
    NEED_LICENSE_EDITOR,
    RENAME_MATRIX_HEADER_FORBIDDEN,
}
