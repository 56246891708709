import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'NewMatrixEdgesListDialog.title',
        defaultMessage: 'Список связей',
    },
    close: {
        id: 'NewMatrixEdgesListDialog.close',
        defaultMessage: 'Закрыть',
    },
    ID: {
        id: 'NewMatrixEdgesListDialog.ID',
        defaultMessage: 'ID',
    },
    elementType: {
        id: 'NewMatrixEdgesListDialog.elementType',
        defaultMessage: 'Тип элемента',
    },
    sourceObject: {
        id: 'NewMatrixEdgesListDialog.sourceObject',
        defaultMessage: 'Исходящий объект',
    },
    edgeType: {
        id: 'NewMatrixEdgesListDialog.edgeType',
        defaultMessage: 'Тип связи',
    },
    targetObject: {
        id: 'NewMatrixEdgesListDialog.targetObject',
        defaultMessage: 'Входящий объект',
    },
    symbol: {
        id: 'NewMatrixEdgesListDialog.symbol',
        defaultMessage: 'Символ',
    },
    instance: {
        id: 'NewMatrixEdgesListDialog.instance',
        defaultMessage: 'Экземпляр связи',
    },
    definition: {
        id: 'NewMatrixEdgesListDialog.definition',
        defaultMessage: 'Определение связи',
    },
    deleteDefinition: {
        id: 'NewMatrixEdgesListDialog.deleteDefinition',
        defaultMessage: 'Удаление определение связи',
    },
    delete: {
        id: 'NewMatrixEdgesListDialog.delete',
        defaultMessage: 'Удалить',
    },
    cancel: {
        id: 'NewMatrixEdgesListDialog.cancel',
        defaultMessage: 'Отмена',
    },
    deleteMessage: {
        id: 'NewMatrixEdgesListDialog.deleteMessage',
        defaultMessage: 'Будет удалено определение связи: ',
    },
});
