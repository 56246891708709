import React, { ChangeEvent, useEffect } from 'react';

import theme from './DesignTab.scss';
import { Symbol } from '@/serverapi/api';
import messages from '../../../ObjectType.messages';
import { useIntl } from 'react-intl';
import { TPreset } from '@/models/preset.types';
import { TreeNode } from '@/models/tree.types';
import { Notice } from '@/modules/UIKit/components/Notice/Notice.component';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { Input } from '@/modules/UIKit/components/Input/Input.component';

import generatorSymbolsSvg from '@/resources/icons/UIKit/generator-symbols.svg';
import importSvg from '@/resources/icons/UIKit/import.svg';
import fontAdditionalSvg from '@/resources/icons/UIKit/font-additional.svg';
import { getRatio } from './DesignTab.utils';

interface IDesignTabProps {
    tmpSymbol: Symbol;
    symbol: Symbol;
    saveProportion: boolean;
    preset: TPreset;
    serverNode: TreeNode;
    setSymbolGeneratorVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setTextEditorVisible: React.Dispatch<React.SetStateAction<boolean>>;
    openFileUploadDialog: (preset: TPreset, serverNode: TreeNode, symbol: Symbol) => void;
    updateSymbol: (symbol: Symbol) => void;
    switchToEditDesignTab: () => void;
}

export const DesignTab = (props: IDesignTabProps) => {
    const {
        switchToEditDesignTab,
        tmpSymbol,
        updateSymbol,
        saveProportion,
        symbol,
        openFileUploadDialog,
        preset,
        serverNode,
        setSymbolGeneratorVisible,
        setTextEditorVisible,
    } = props;

    const intl = useIntl();

    useEffect((): void => {
        switchToEditDesignTab();
    }, []);

    const onChangeWidth = (event: ChangeEvent<HTMLInputElement>): void => {
        const width = Number(event.target.value);

        updateSymbol({
            ...tmpSymbol,
            width,
            height: (saveProportion && width / getRatio(tmpSymbol?.height, tmpSymbol?.width)) || tmpSymbol?.height,
        });

        switchToEditDesignTab();
    };

    const onChangeHeight = (event: ChangeEvent<HTMLInputElement>): void => {
        const height = Number(event.target.value);

        updateSymbol({
            ...tmpSymbol,
            height,
            width: (saveProportion && height / getRatio(tmpSymbol?.width, tmpSymbol?.height)) || tmpSymbol?.width,
        });

        switchToEditDesignTab();
    };

    const resetAllSettings = (): void => {
        updateSymbol(symbol);
        switchToEditDesignTab();
    };

    return (
        <div>
            {tmpSymbol.graphical || tmpSymbol.creationType === 'SYMBOL_GENERATOR' ? ( // временно пока не появится поле у символа в апи
                <div>
                    <Notice>
                        {tmpSymbol.creationType === 'SYMBOL_GENERATOR'
                            ? intl.formatMessage(messages.createSymbolThroughGENERATOR)
                            : intl.formatMessage(messages.createSymbolThroughSVG)}
                    </Notice>

                    <div className={theme.actionsRow}>
                        {symbol.creationType !== 'SYMBOL_GENERATOR' && tmpSymbol.creationType !== 'SYMBOL_GENERATOR' ? (
                            <Button icon={importSvg} onClick={() => openFileUploadDialog(preset, serverNode, symbol)}>
                                <span>{intl.formatMessage(messages.newDownload)}</span>
                            </Button>
                        ) : (
                            <Button icon={generatorSymbolsSvg} onClick={() => setSymbolGeneratorVisible(true)}>
                                <span>{intl.formatMessage(messages.symbolGenerator)}</span>
                            </Button>
                        )}

                        <Button onClick={resetAllSettings}>
                            <span>{intl.formatMessage(messages.cancelAllSettings)}</span>
                        </Button>
                        <Button icon={fontAdditionalSvg} onClick={() => setTextEditorVisible(true)}>
                            <span>{intl.formatMessage(messages.symbolEditorButton)}</span>
                        </Button>
                    </div>

                    <span className={theme.settingsTitle}>
                        {intl.formatMessage(messages.defaultGeometrySettingsTitle)}
                    </span>

                    <div className={theme.inputsRow}>
                        <Input
                            className={theme.input}
                            label={intl.formatMessage(messages.width)}
                            name="symbolWidth"
                            data-test="edit-symbol-width-input"
                            value={tmpSymbol?.width || ''}
                            onChange={onChangeWidth}
                        />

                        <Input
                            className={theme.input}
                            label={intl.formatMessage(messages.height)}
                            name="symbolHeight"
                            data-test="edit-symbol-height-input"
                            value={tmpSymbol?.height || ''}
                            onChange={onChangeHeight}
                        />
                    </div>
                </div>
            ) : (
                <div>
                    <Notice>{intl.formatMessage(messages.symbolSelectCreationType)}</Notice>
                    <div className={theme.actionsRow}>
                        <Button
                            icon={importSvg}
                            onClick={() => props.openFileUploadDialog(props.preset, props.serverNode, props.symbol)}
                        >
                            <span>{intl.formatMessage(messages.importSymbolButton)}</span>
                        </Button>
                        <Button
                            icon={generatorSymbolsSvg}
                            onClick={() => setSymbolGeneratorVisible(true)}
                            data-test="symbol-editor_symbol-generator-btn"
                        >
                            <span>{intl.formatMessage(messages.symbolGenerator)}</span>
                        </Button>
                        <Button icon={fontAdditionalSvg} onClick={() => setTextEditorVisible(true)}>
                            <span>{intl.formatMessage(messages.symbolEditorButton)}</span>
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};
