import React, { useState, useEffect, useRef } from 'react';
import { useIntl, WrappedComponentProps } from 'react-intl';
import { v4 as uuid } from 'uuid';

import { Tabs } from '@/modules/UIKit/components/Tabs/Tabs.component';
import { SymbolTextEditorDialog } from '@/modules/AdminTools/Methodology/components/Presets/ObjectType/Dialog/SymbolTextEditorDialog.component';
import { TPreset } from '@/models/preset.types';
import { TreeNode } from '@/models/tree.types';
import { InternationalString, ObjectType, Symbol, SymbolSettings } from '@/serverapi/api';
import { Locale } from '@/modules/Header/components/Header/header.types';
import { MxCell, MxPoint } from 'MxGraph';
import { ObjectDefinitionImpl } from '@/models/bpm/bpm-model-impl';
import { SequenceSymbolTypeId, SymbolTypeId } from '@/mxgraph/ComplexSymbols/symbols/ComplexSymbol.constants';
import { MethodologiesGraph } from '@/mxgraph/MethodologiesGraph';
import { ApiBundle } from '@/services/api/api-bundle';
import { ComplexSymbolManager } from '@/mxgraph/ComplexSymbols/ComplexSymbolManager.class';
import {
    checkXmlParseError,
    parseDomToXml,
    parseXmlToDom,
} from '@/modules/AdminTools/Methodology/components/Presets/util/preset.utils';
import messages from '../ObjectType.messages';
import symbolsTypesMessages from '../SymbolsTypes.messages';

import { getPointsSymbolEditorTab } from '@/mxgraph/util/PortsDefinitions.utils';
import { MxUtils } from '@/mxgraph/mxgraph';
import { MxConstants } from '@/mxgraph/mxgraph';
import { EditorMode } from '@/models/editorMode';
import { DefaultGraph } from '@/mxgraph/DefaultGraph';
import { symbolService } from '@/services/SymbolsService';
import { insertSymbolToGraph } from '@/sagas/editor.saga.utils';
import SymbolsTypesMessages from '@/modules/AdminTools/Methodology/components/Presets/ObjectType/SymbolsTypes.messages';
import { SymbolGeneratorDialog } from '@/modules/AdminTools/Methodology/components/Presets/ObjectType/Dialog/SymbolGenerator/SymbolGeneratorDialog.component';
import { SymbolEditorHeader } from './components/SymbolEditorHeader/SymbolEditorHeader.component';
import { previousActionEnum, TConstraintsPoint, TSymbolHeaderForm } from './SymbolEditorTab.types';
import { usePrevious } from '@/hooks/usePrevious';
import { PointsTab } from './components/PointsTab/PointsTab.component';
import { LabelTab } from './components/LabelTab/LabelTab.component';
import { IconTab } from './components/IconTab/IconTab.components';

import { DesignTab } from './components/DesignTab/DesignTab.component';
import { Footer } from './components/Footer/Footer.components';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import useUpdateEffect from '@/hooks/useUpdateEffect';
import { TabsProps } from 'antd';

import theme from './SymbolEditorTab.scss';

type TSymbolEditorTabProps = {
    symbol: Symbol;
    preset: TPreset;
    serverNode: TreeNode;
    isNewSymbol: boolean;
    objectType: ObjectType;
    graphical?: string;
    currentLocale: Locale;
};

type TSymbolEditorTabActionProps = {
    onCancel: () => void;
    submitSymbol: (preset: TPreset, serverNode: TreeNode, symbol: Symbol) => void;
    createSymbol: (preset: TPreset, serverNode: TreeNode, symbol: Symbol) => void;
    openFileUploadDialog: (preset: TPreset, serverNode: TreeNode, symbol: Symbol) => void;
};

type TSymbolEditorTabFullProps = TSymbolEditorTabProps & TSymbolEditorTabActionProps & WrappedComponentProps;

enum SymbolMenu {
    DESIGN,
    LABEL,
    POINTS,
    ICON,
}

const tmpSymbolId = 'tmpSymbolId';
const nodeId = {
    serverId: uuid(),
    repositoryId: uuid(),
    id: uuid(),
};

const objectDefinitionImpl = new ObjectDefinitionImpl({
    nodeId,
    isDirty: true,
});

export const SymbolEditorTab = (props: TSymbolEditorTabFullProps) => {
    const { symbol, graphical, onCancel, isNewSymbol, openFileUploadDialog, createSymbol, submitSymbol } = props;
    const intl = useIntl();
    const graphRef = useRef<HTMLDivElement>(null);
    const graph = useRef<MethodologiesGraph>();
    let { width } = props.symbol;
    let { height } = props.symbol;
    const currentLocale = useSelector(getCurrentLocale);

    if (!(width && height)) {
        const parser = new DOMParser();
        const graphical = parser.parseFromString(props.graphical || props.symbol.graphical, 'application/xml');
        height = graphical.documentElement.getAttribute('h')
            ? Number(graphical.documentElement.getAttribute('h'))
            : undefined;
        width = graphical.documentElement.getAttribute('w')
            ? Number(graphical.documentElement.getAttribute('w'))
            : undefined;
    }
    const [tmpSymbol, setTmpSymbol] = useState({
        ...symbol,
        width,
        height,
        id: tmpSymbolId,
        objectType: tmpSymbolId,
        graphical: graphical || symbol.graphical,
    });
    const { symbolTypeId } = tmpSymbol;
    const symbolType =
        (SymbolsTypesMessages[symbolTypeId || ''] && intl.formatMessage(symbolsTypesMessages[symbolTypeId || ''])) ||
        '';
    const [contentId, setContentId] = useState(SymbolMenu.DESIGN);
    const [constraintPoints, setConstraintPoints] = useState<TConstraintsPoint[]>([]);
    const [isEditableSymbol, setIsEditableSymbol] = useState(true);
    const [saveProportion] = useState(false);
    const [id, setId] = useState<string>(symbol.id);
    const [synonymsIds, setSynonymsIds] = useState<string[] | undefined>(symbol.synonymsIds);
    const [multilingualName, setMultilingualName] = useState<InternationalString | undefined>(
        tmpSymbol?.multilingualName,
    );

    const formValues = { id, synonymsIds, symbolType, multilingualName };

    const {
        register,
        trigger,
        formState: { errors },
    } = useForm<TSymbolHeaderForm>({ values: formValues, mode: 'onChange' });

    const isEmptyName = !multilingualName?.[currentLocale];

    const [isDoubleConstraintPoints, setIsDoubleConstraintPoints] = useState(false);
    const [previousAction, setPreviousAction] = useState(previousActionEnum.NONE);
    const [textEditorVisible, setTextEditorVisible] = useState(false);
    const [symbolGeneratorVisible, setSymbolGeneratorVisible] = useState(false);
    const prevTmpSymbol = usePrevious(tmpSymbol);
    const prevGraphical = usePrevious(graphical);
    const prevSymbol = usePrevious(symbol);
    const [cell, setCell] = useState<MxCell>();

    const switchToEditDesignTab = (): void => {
        graph.current?.getModel().beginUpdate();
        graph.current?.setMode(EditorMode.Read);
        graph.current?.setCellsSelectable(false);
        graph.current?.setCellsMovable(false);
        graph.current?.getModel().endUpdate();
    };

    useEffect(() => {
        if (graphRef.current && !graph.current) {
            graph.current = new MethodologiesGraph({ container: graphRef.current });
            graph.current.setSymbolName(symbol.name);
            graph.current.setIntl(intl);
            graph.current.bpmMxGraphContext = {
                api: {} as ApiBundle,
                serverId: props.serverNode.nodeId.serverId,
                objectDefinitionCopyPasteContext: {},
            };
        }

        setEditingRulesSymbol(tmpSymbol);
        updateSymbol(tmpSymbol);
        switchToEditDesignTab();
        setInitialConstraintsPoints();

        return () => {
            graph.current && graph.current.destroy();
            setIsDoubleConstraintPoints(false);
        };
    }, []);

    useUpdateEffect((): void => {
        if (tmpSymbol.graphical !== prevTmpSymbol.graphical) {
            setInitialConstraintsPoints();
        }

        if (prevGraphical !== props.graphical && prevSymbol.graphical !== props.symbol.graphical) {
            const symbol = {
                ...tmpSymbol,
                graphical: props.graphical || props.symbol.graphical,
                // некоторые символы добавляются по шейпу из стилей так что для временного символа
                // тоже добавим в стили временный шейп. Для bpmn.
                // style: newProps.symbol.style?.split(';').filter(s => s.indexOf( MxConstants.STYLE_SHAPE) < 0)?.join(';')
            };

            updateSymbol(symbol);
            switchToEditDesignTab();
        }
    });

    const setEditingRulesSymbol = (symbol: Symbol): void => {
        const symbolTypeId: SymbolTypeId | SequenceSymbolTypeId = ComplexSymbolManager.getSymbolType(symbol);
        const isHorizontalSwimlane: boolean = symbolTypeId === SymbolTypeId.HORIZONTAL_SWIMLANE;
        const isVerticalSwimlane: boolean = symbolTypeId === SymbolTypeId.VERTICAL_SWIMLANE;
        const isVR: boolean = symbolTypeId === SymbolTypeId.VR;
        const isLifeLine: boolean = [
            SequenceSymbolTypeId.LIFE_LINE,
            SequenceSymbolTypeId.LIFE_LINE_BOUNDERY,
            SequenceSymbolTypeId.LIFE_LINE_CONTROL,
            SequenceSymbolTypeId.LIFE_LINE_ENTITY,
            SequenceSymbolTypeId.LIFE_LINE_ACTOR,
        ].includes(symbolTypeId as SequenceSymbolTypeId);
        const isUmlMessage: boolean = symbolTypeId === SymbolTypeId.UML_MESSAGE;
        const isEditableSymbol: boolean = !(
            isHorizontalSwimlane ||
            isVerticalSwimlane ||
            isVR ||
            isLifeLine ||
            isUmlMessage
        );

        setIsEditableSymbol(isEditableSymbol);
        setContentId(isEditableSymbol ? contentId : SymbolMenu.ICON);
    };

    const checkIsCorrectGraphical = (graphical: string): boolean => {
        /* На основе graphical парсер создает DOM для инициализации точек соединения.
         Проверяем, XML это, просто текст или пустая строка */
        const graphicalDOM: Document = parseXmlToDom(graphical);

        return !checkXmlParseError(graphicalDOM);
    };

    const getConstraintPointsFromXml = (): TConstraintsPoint[] | undefined => {
        const { graphical } = tmpSymbol;
        const graphicalDOM: Document = parseXmlToDom(graphical);
        const constraints = Array.from(graphicalDOM.getElementsByTagName('constraint'));

        if (!constraints.length) {
            return undefined;
        }

        const points: TConstraintsPoint[] = constraints.map((constraint) => {
            const attributes: Attr[] = Array.from(constraint.attributes);
            const name = attributes[0]?.value || '';
            const x = attributes[2]?.value || '';
            const y = attributes[3]?.value || '';
            const id = attributes[4]?.value || uuid();

            return { name, coordinatesX: x, coordinatesY: y, id };
        });

        return points;
    };

    const setUpdateGraphical = (graphical: string): void => {
        if (checkIsCorrectGraphical(graphical)) {
            updateSymbol({
                ...tmpSymbol,
                graphical,
            });
        } else {
            setTmpSymbol((state) => ({ ...state, graphical }));
        }
    };

    const setPointsToXml = (points: TConstraintsPoint[]): void => {
        const { graphical } = tmpSymbol;
        const graphicalDOM: Document = parseXmlToDom(graphical);

        if (graphicalDOM.getElementsByTagName('connections')[0]) {
            graphicalDOM.getElementsByTagName('connections')[0].innerHTML = '';

            points.forEach((point): void => {
                const constraint = `<constraint name="${point.name}" perimeter="0" x="${point.coordinatesX}" y="${point.coordinatesY}" id="${point.id}"/>`;
                graphicalDOM.getElementsByTagName('connections')[0].insertAdjacentHTML('beforeend', constraint);
            });

            setUpdateGraphical(parseDomToXml(graphicalDOM));
        }
    };

    const setInitialConstraintsPoints = (): void => {
        const { graphical } = tmpSymbol;
        const isCorrectGraphical = checkIsCorrectGraphical(graphical);

        if (!isCorrectGraphical) {
            return;
        }

        const points = getConstraintPointsFromXml();

        if (points) {
            setConstraintPoints(points);
        }

        if (!points) {
            const defaultPoints = getPointsSymbolEditorTab();

            setPointsToXml(defaultPoints);
            setConstraintPoints(defaultPoints);
        }
    };

    const unlockGraph = (): void => {
        graph.current?.setMode(EditorMode.Edit);
    };

    const insertSymbol = (symbol: Symbol, graph: DefaultGraph): MxCell => {
        symbolService().applyStylesToGraph(graph, [symbol]);
        // enable for custom shapes like bpmn
        // MxCellRenderer.registerShape(symbol.id, new MxShape(MxStencilRegistry.getStencil(symbol.id)));

        return insertSymbolToGraph({
            point: new MxPoint(32, 24),
            symbol,
            graph,
            objectDefinitions: [{ ...objectDefinitionImpl, name: symbol.name }] as ObjectDefinitionImpl[],
        });
    };

    const updateSymbol = (symbol): void => {
        if (graph.current) {
            unlockGraph();
            graph.current.clear();

            const geometry = ComplexSymbolManager.getSymbolPreviewGeometry(symbol, cell);
            const tmpSymbol = { ...symbol, ...geometry };
            const newCell = insertSymbol(tmpSymbol, graph.current);

            setTmpSymbol(tmpSymbol);
            setCell(newCell);
        }
    };

    const getTabItems = (): TabsProps['items'] => {
        if (!isEditableSymbol) {
            return [
                {
                    key: SymbolMenu.ICON.toString(),
                    label: intl.formatMessage(messages.icon),
                },
            ];
        }

        return [
            {
                key: SymbolMenu.DESIGN.toString(),
                label: intl.formatMessage(messages.design),
            },
            {
                key: SymbolMenu.LABEL.toString(),
                label: intl.formatMessage(messages.text),
            },
            {
                key: SymbolMenu.POINTS.toString(),
                label: intl.formatMessage(messages.pointsOfConnection),
            },
            {
                key: SymbolMenu.ICON.toString(),
                label: intl.formatMessage(messages.icon),
            },
        ];
    };

    const getTabContent = (key: SymbolMenu): JSX.Element => {
        const tabs = {
            [SymbolMenu.ICON]: (
                <IconTab
                    tmpSymbol={tmpSymbol}
                    symbol={symbol}
                    updateSymbol={updateSymbol}
                    graph={graph.current}
                    cell={cell}
                    setCell={setCell}
                    onChangeStyle={onChangeStyle}
                    getStyleValue={getStyleValue}
                    isEditableSymbol={isEditableSymbol}
                />
            ),
            [SymbolMenu.DESIGN]: (
                <DesignTab
                    switchToEditDesignTab={switchToEditDesignTab}
                    tmpSymbol={tmpSymbol}
                    symbol={symbol}
                    updateSymbol={updateSymbol}
                    saveProportion={saveProportion}
                    openFileUploadDialog={openFileUploadDialog}
                    preset={props.preset}
                    serverNode={props.serverNode}
                    setSymbolGeneratorVisible={setSymbolGeneratorVisible}
                    setTextEditorVisible={setTextEditorVisible}
                />
            ),
            [SymbolMenu.LABEL]: (
                <LabelTab
                    switchToEditTextTab={switchToEditTextTab}
                    tmpSymbol={tmpSymbol}
                    symbol={symbol}
                    updateSymbol={updateSymbol}
                    graph={graph.current}
                    cell={cell}
                    setCell={setCell}
                    onChangeStyle={onChangeStyle}
                    getStyleValue={getStyleValue}
                />
            ),
            [SymbolMenu.POINTS]: (
                <PointsTab
                    constraintPoints={constraintPoints}
                    previousAction={previousAction}
                    onChangeConstraintPoints={setConstraintPoints}
                    isDoubleConstraintPoints={isDoubleConstraintPoints}
                    onChangeIsDoubleConstraintPoints={setIsDoubleConstraintPoints}
                    switchToEditPointsTab={switchToEditPointsTab}
                    addConstraintsPoint={addConstraintsPoint}
                    deleteConstraintsPoint={deleteConstraintsPoint}
                    onChangePreviousAction={setPreviousAction}
                />
            ),
        };

        return tabs[key];
    };

    const switchToEditTextTab = (): void => {
        if (graph.current) {
            graph.current.getModel().beginUpdate();
            graph.current.setMode(EditorMode.Edit);
            graph.current.setCellsMovable(true);
            graph.current.setCellsResizable(true);
            graph.current.setVertexLabelsMovable(true);
            graph.current.graphHandler?.setMoveEnabled(true);

            if (cell) {
                MxUtils.setCellStyles(graph.current.getModel(), [cell], MxConstants.STYLE_MOVABLE, 0);
                MxUtils.setCellStyles(graph.current.getModel(), [cell], MxConstants.STYLE_RESIZABLE, 0);
            }

            graph.current.getModel().endUpdate();
        }
    };

    const switchToEditPointsTab = (): void => {
        switchToEditTextTab();
        graph.current?.setMode(EditorMode.Edit);
    };

    const addConstraintsPoint = (constraints: TConstraintsPoint): void => {
        const { coordinatesX, coordinatesY, name, id } = constraints;
        const { graphical } = tmpSymbol;
        const graphicalDOM: Document = parseXmlToDom(graphical);

        const constraint = `<constraint name="${name}" perimeter="0" x="${coordinatesX}" y="${coordinatesY}" id="${id}"/>`;

        if (graphicalDOM.getElementsByTagName('connections')[0]) {
            graphicalDOM.getElementsByTagName('connections')[0].insertAdjacentHTML('beforeend', constraint);
            setUpdateGraphical(parseDomToXml(graphicalDOM));
        } else {
            setUpdateGraphical(constraint);
        }
    };

    const deleteConstraintsPoint = (pointId: string): void => {
        const points = getConstraintPointsFromXml();

        if (!points) {
            return;
        }

        const filteredPoints = points.filter((point) => point.id !== pointId);

        setPointsToXml(filteredPoints);
        setConstraintPoints(filteredPoints);
    };

    const onChangeStyle = (key: string, value: any): void => {
        const labelStyle = MxUtils.setStyle(tmpSymbol?.labelStyle || '', key, value);
        const newSymbol = { ...tmpSymbol, labelStyle };

        updateSymbol(newSymbol);
        switchToEditTextTab();
    };

    const getStyleValue = (key: string, style?: string): string | undefined => {
        return (style || tmpSymbol?.labelStyle)
            ?.split(';')
            .find((n) => n.indexOf(key) >= 0)
            ?.split('=')[1];
    };

    const handleSelect = (newTabKey: string): void => {
        const newContentId = Number(newTabKey);

        if (contentId !== newContentId) {
            setContentId(newContentId);
        }
    };

    return (
        <div className={theme.symbolEditorTabContainer}>
            <span className={theme.navigationTitle}>
                {intl.formatMessage(messages.symbolBreadCrumbs, {
                    presetName: props.preset.name,
                    objectTypeName: props.objectType.name,
                    symbolName: props.symbol.name,
                })}
            </span>
            <div className={theme.tabContent}>
                <SymbolEditorHeader
                    disableId={!isNewSymbol}
                    id={id}
                    onChangeId={(newId): void => {
                        setId(newId);
                        trigger();
                    }}
                    onChangeSynonymsIds={setSynonymsIds}
                    synonymsIds={synonymsIds}
                    onChangeName={(newName): void => {
                        setMultilingualName(newName);
                        trigger();
                    }}
                    multilingualName={multilingualName}
                    register={register}
                    errors={errors}
                />
                <div className={theme.editor}>
                    <Tabs
                        className={theme.tabs}
                        defaultActiveKey={SymbolMenu.DESIGN.toString()}
                        activeKey={contentId.toString()}
                        onChange={handleSelect}
                        items={getTabItems()}
                    />
                    <div className={theme.symbolSettings}>{getTabContent(contentId)}</div>
                    <div className={theme.symbolPreview}>
                        <span className={theme.presentationTitle}>{intl.formatMessage(messages.previewSymbol)}</span>

                        <div className={theme.presentation}>
                            <div className={theme.graphContainer} ref={graphRef} />
                        </div>
                        {/* {this.renderSVG()} */}
                    </div>
                </div>
            </div>
            <div className={theme.footer}>
                <Footer
                    onCancel={onCancel}
                    tmpSymbol={tmpSymbol}
                    symbol={symbol}
                    isEmptyName={isEmptyName}
                    synonymsIds={synonymsIds}
                    id={id}
                    cell={cell}
                    currentLocale={props.currentLocale}
                    isNewSymbol={isNewSymbol}
                    preset={props.preset}
                    serverNode={props.serverNode}
                    createSymbol={createSymbol}
                    submitSymbol={submitSymbol}
                    multilingualName={multilingualName}
                />
            </div>

            {textEditorVisible && (
                <SymbolTextEditorDialog
                    graphical={tmpSymbol.graphical}
                    onSubmit={(graphical: string): void => {
                        setUpdateGraphical(graphical);
                        updateSymbol({
                            ...tmpSymbol,
                            graphical,
                            creationType: 'IMAGE',
                        });
                        setTextEditorVisible(false);
                    }}
                    onClose={(): void => setTextEditorVisible(false)}
                />
            )}
            {symbolGeneratorVisible && (
                <SymbolGeneratorDialog
                    tmpSymbol={tmpSymbol}
                    nodeId={props.serverNode.nodeId}
                    presetId={props.preset.id}
                    onClose={(): void => setSymbolGeneratorVisible(false)}
                    onSubmit={(graphical: string, currentStyles: SymbolSettings): void => {
                        setUpdateGraphical(graphical);
                        updateSymbol({
                            ...tmpSymbol,
                            graphical,
                            width: currentStyles.symbolSettings?.width,
                            height: currentStyles.symbolSettings?.height,
                            creationType: 'SYMBOL_GENERATOR',
                            symbolSettings: currentStyles,
                        });
                        setSymbolGeneratorVisible(false);
                    }}
                />
            )}
        </div>
    );
};

export default SymbolEditorTab;
