import React, { FC } from 'react';
import { Table } from 'semantic-ui-react';
import theme from './NewMatrixMainHeader.scss';
import { InternationalString, MatrixDataBPM8764 } from '@/serverapi/api';
import { LocalesService } from '@/services/LocalesService';
import { Locale } from '@/modules/Header/components/Header/header.types';
import messages from './NewMatrixMainHeader.messages';
import { useIntl } from 'react-intl';
import { HeaderResizeDirections, THeaderResizeData } from '../NewMatrix.types';
import cn from 'classnames';
import { MainHeader } from './MainHeader.component';

type TNewMatrixMainHeaderProps = {
    rowHeaderWidth: number;
    columnHeaderHeight: number;
    currentLocale: Locale;
    isReadMode: boolean;
    data?: MatrixDataBPM8764;
    setHeaderResizeData: (resizingData: THeaderResizeData) => void;
    saveMatrixData: (data: MatrixDataBPM8764) => void;
    openRenameDialog: (onSubmit: (newName: InternationalString) => void, initName?: InternationalString) => void;
};

export const NewMatrixMainHeader: FC<TNewMatrixMainHeaderProps> = ({
    rowHeaderWidth,
    columnHeaderHeight,
    data,
    currentLocale,
    isReadMode,
    setHeaderResizeData,
    saveMatrixData,
    openRenameDialog,
}) => {
    const intl = useIntl();

    const localeColName: string =
        LocalesService.internationalStringToString(data?.columnsName, currentLocale) ||
        intl.formatMessage(messages.withoutName);
    const localeRowName: string =
        LocalesService.internationalStringToString(data?.rowsName, currentLocale) ||
        intl.formatMessage(messages.withoutName);

    const colRenameHandler = () => {
        const mainHeaderColRename = (newName: InternationalString) => {
            if (data) {
                const newMatrixData: MatrixDataBPM8764 = { ...data, columnsName: newName };
                saveMatrixData(newMatrixData);
            }
        };

        openRenameDialog(mainHeaderColRename, data?.columnsName);
    };

    const rowRenameHandler = () => {
        const mainHeaderRowRename = (newName: InternationalString) => {
            if (data) {
                const newMatrixData: MatrixDataBPM8764 = { ...data, rowsName: newName };
                saveMatrixData(newMatrixData);
            }
        };

        openRenameDialog(mainHeaderRowRename, data?.rowsName);
    };

    return (
        <Table.HeaderCell
            className={theme.activeCell}
            style={{
                height: `${columnHeaderHeight}px`,
                width: `${rowHeaderWidth}px`,
                minHeight: `${columnHeaderHeight}px`,
                minWidth: `${rowHeaderWidth}px`,
                maxHeight: `${columnHeaderHeight}px`,
                maxWidth: `${rowHeaderWidth}px`,
            }}
        >
            <div
                className={theme.headerContainer}
                style={{
                    height: `${columnHeaderHeight}px`,
                    width: `${rowHeaderWidth}px`,
                }}
            >
                <MainHeader
                    isColumn={false}
                    text={localeRowName}
                    columnHeaderHeight={columnHeaderHeight}
                    rowHeaderWidth={rowHeaderWidth}
                    isReadMode={isReadMode}
                    rename={rowRenameHandler}
                />
                <MainHeader
                    isColumn
                    text={localeColName}
                    columnHeaderHeight={columnHeaderHeight}
                    rowHeaderWidth={rowHeaderWidth}
                    isReadMode={isReadMode}
                    rename={colRenameHandler}
                />
            </div>
            <div
                className={cn({ [theme.bothSizer]: !isReadMode })}
                onDragStart={(e) => {
                    e.preventDefault();
                }}
                onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
                    if (isReadMode) return;

                    e.stopPropagation();
                    e.preventDefault();

                    setHeaderResizeData({
                        isResizing: true,
                        direction: HeaderResizeDirections.both,
                    });
                }}
            />
        </Table.HeaderCell>
    );
};
