import type { TRichTextEditorToolbarProps } from './RichTextEditorToolbar.types';
import React, { FC } from 'react';
import cx from 'classnames';
import theme from '../../Toolbar/Toolbar.scss';
import { getStateObserver } from '../common/sharedState.class';
import { ControlsContext } from './Controls.context';
import { TextAlignComponent } from './controls/TextAlign.component';
import { BGColorComponent } from './controls/BGColor.component';
import { FontColorComponent } from './controls/FontColor.component';
import { FontFamilyComponent } from './controls/FontFamily.component';
import { FontSizeComponent } from './controls/FontSize.component';
import { InlineStyleComponent } from './controls/InlineStyle.component';
import { ListsComponent } from './controls/Lists.component';
import { ImageComponent } from './controls/Image.component';
import { TableComponent } from './controls/Table.component';
import { LinksComponent } from './controls/Links.component';
import { EraserComponent } from './controls/Eraser.component';
import { LineHeightComponent } from './controls/LineHeight.component';
import { DividerComponent } from './controls/Divider.component';
import { IndentComponent } from './controls/Indent.component';
import { ParagraphStyle } from './controls/ParagraphStyle.component';

export const RichTextEditorToolbar: FC<TRichTextEditorToolbarProps> = ({ id, handlers }) => {
    const stateObserverStateObserver = getStateObserver(id);
    const { openImageDialog, openLinkDialog, imageLinkMapper } = handlers;
    const groupClassName = cx(theme.controlsGroup, { [theme.controlsGroupCompact]: true });

    return (
        <div
            className={cx(theme.toolbarContainer, { [theme.toolbarContainerCompact]: true })}
            data-test="wiki-toolbar-group"
        >
            {stateObserverStateObserver && (
                <ControlsContext.Provider value={stateObserverStateObserver}>
                    <div className={groupClassName}>
                        <ParagraphStyle />
                    </div>
                    <DividerComponent />
                    <div className={groupClassName}>
                        <FontFamilyComponent />
                    </div>
                    <DividerComponent />
                    <div className={groupClassName}>
                        <FontSizeComponent />
                    </div>
                    <DividerComponent />
                    <div className={groupClassName}>
                        <LineHeightComponent />
                    </div>
                    <DividerComponent />
                    <div className={groupClassName}>
                        <FontColorComponent />
                        <BGColorComponent />
                    </div>
                    <DividerComponent />
                    <div className={groupClassName}>
                        <InlineStyleComponent />
                    </div>
                    <DividerComponent />
                    <div className={groupClassName}>
                        <TextAlignComponent />
                        <IndentComponent />
                    </div>
                    <DividerComponent />
                    <div className={groupClassName}>
                        <ListsComponent />
                    </div>
                    <DividerComponent />
                    <div className={groupClassName}>
                        <LinksComponent openDialog={openLinkDialog} />
                        <ImageComponent openDialog={openImageDialog} linkMapper={imageLinkMapper} />
                        <TableComponent />
                    </div>
                    <DividerComponent />
                    <div className={groupClassName}>
                        <EraserComponent />
                    </div>
                </ControlsContext.Provider>
            )}
        </div>
    );
};
