import React, { CSSProperties, FC } from 'react';
import { Tooltip } from 'antd';
import theme from './NewMatrixMainHeader.scss';
import { getTextLanes } from './getTextLanes';
import { TMainHeaderLanes } from '../NewMatrix.types';

type TMainHeaderProps = {
    isColumn: boolean;
    text: string;
    columnHeaderHeight: number;
    rowHeaderWidth: number;
    isReadMode: boolean;
    rename: () => void;
};

export const MainHeader: FC<TMainHeaderProps> = ({
    isColumn,
    text,
    columnHeaderHeight,
    rowHeaderWidth,
    isReadMode,
    rename,
}) => {
    const { lanes, topPadding, showTooltip, sidePadding }: TMainHeaderLanes = getTextLanes(
        isColumn,
        text,
        columnHeaderHeight,
        rowHeaderWidth,
    );

    const style: CSSProperties = {
        cursor: `${isReadMode ? 'default' : 'pointer'}`,
        paddingTop: `${topPadding}px`,
        paddingRight: isColumn ? `${sidePadding}px` : 'unset',
        paddingLeft: isColumn ? 'unset' : `${sidePadding}px`,
    };

    return (
        <div
            onClick={rename}
            className={isColumn ? theme.colsHeaderTextContainer : theme.rowsHeaderTextContainer}
            style={style}
        >
            {showTooltip ? (
                <Tooltip
                    placement="bottom"
                    mouseLeaveDelay={0}
                    title={text}
                    key={'matrixMainHeaderColsTooltip'}
                    destroyTooltipOnHide
                    className={isColumn ? theme.tooltip : ''}
                >
                    {lanes.map((lane) => lane)}
                </Tooltip>
            ) : (
                lanes.map((lane) => lane)
            )}
        </div>
    );
};
