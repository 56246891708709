import { TRootState } from '../../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { MatrixNode, NodeId } from '../../serverapi/api';
import { TMatrixState } from '../../reducers/entities/matrix.reducer.types';

const matrixStateSelector = (state: TRootState): TMatrixState => state.matrix;

export namespace MatrixSelectors {
    export const byId = (nodeId: NodeId) =>
        createSelector<TRootState, TMatrixState, MatrixNode | undefined>(matrixStateSelector, (state) =>
            state.get(nodeId),
        );
    export const byIds = (nodeIds: NodeId[]) =>
        createSelector<TRootState, TMatrixState, MatrixNode[]>(matrixStateSelector, (state) => {
            const matrixArr: MatrixNode[] = nodeIds
                .map((nodeId) => {
                    const matrix: MatrixNode | undefined = state.get(nodeId);
                    return matrix;
                })
                .filter((element): element is MatrixNode => !!element);

            return matrixArr;
        });

    export const isMatrixAutofilled = (nodeId: NodeId) =>
        createSelector<TRootState, TMatrixState, boolean>(
            matrixStateSelector,
            (state) => !!state.get(nodeId)?.data2?.cellSettings.isAutomatic,
        );
}
