import React from 'react';
import theme from './ApprovalDialog.scss';
import messages from './ApprovalDialog.messages';
import { ApprovalUserDTO } from '@/serverapi/api';
import icRemoveApprovalUser from '../../resources/icons/ic-remove-approval-user.svg';
import icApprovalUser from '../../resources/icons/ic-approval-user.svg';
import { Icon } from '../UIKit';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import icAddAssistant from '../../resources/icons/ic-add-approval-stage.svg';
import { useIntl } from 'react-intl';
import { Button } from '../UIKit/components/Button/Button.component';

export const DragDropContextComponent = ({
    isTemplate,
    isCreateMode,
    onApprovalUserRemove,
    onApprovalAssistantRemove,
    userIdToAddAssistantTo,
    setUserIdToAddAssistantTo,
    isEditingDisabled,
    setIsSelectUserOpen,
    approvalUsersDTO,
    onDragEnd,
}) => {
    const intl = useIntl();

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
                droppableId="droppable"
                renderClone={(draggableProvided, snapshot, rubric) => {
                    return (
                        <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                        >
                            <div className={theme.principalsContainer}>
                                <span className={theme.principalsInnerContainer}>
                                    <svg className={theme.principalSvg} viewBox={icApprovalUser.viewBox}>
                                        <use xlinkHref={`#${icApprovalUser.id}`} />
                                    </svg>
                                </span>
                                {(approvalUsersDTO as ApprovalUserDTO[])[rubric.source.index].name}
                            </div>
                        </div>
                    );
                }}
            >
                {(droppableProvided) => (
                    <div
                        className={theme.usersContainer}
                        {...droppableProvided.droppableProps}
                        ref={droppableProvided.innerRef}
                    >
                        {(approvalUsersDTO as ApprovalUserDTO[]).map((user, index) => {
                            const isDragDisabled: boolean = isTemplate
                                ? false
                                : (!isCreateMode && (!!user.vote || !!user.comment)) || isEditingDisabled;
                            const isDeleteUserDisabled: boolean = isTemplate
                                ? false
                                : isEditingDisabled || !!user.vote || !!user.comment;

                            return (
                                <Draggable
                                    key={`${user.principalId}`}
                                    draggableId={`${user.principalId}`}
                                    index={index}
                                    isDragDisabled={isDragDisabled}
                                >
                                    {(draggableProvided) => (
                                        <div
                                            ref={draggableProvided.innerRef}
                                            {...draggableProvided.draggableProps}
                                            {...draggableProvided.dragHandleProps}
                                        >
                                            <div className={theme.user}>
                                                <div
                                                    className={classNames(theme.userInfo, {
                                                        [theme.dragDisabled]: isDragDisabled,
                                                    })}
                                                >
                                                    <span>
                                                        <Icon spriteSymbol={icApprovalUser} />
                                                    </span>
                                                    {user.name}
                                                </div>
                                                <div className={theme.flexCenter}>
                                                    <Button
                                                        disabled={isDeleteUserDisabled}
                                                        onClick={() => {
                                                            setIsSelectUserOpen(true);
                                                            setUserIdToAddAssistantTo(user.principalId);
                                                        }}
                                                        visualStyle="text"
                                                        onBlur={(e) => {
                                                            const isClickedOnSelect =
                                                                e.relatedTarget?.tagName === 'INPUT' &&
                                                                (e.relatedTarget as any).type === 'search';
                                                            if (userIdToAddAssistantTo && !isClickedOnSelect) {
                                                                setIsSelectUserOpen(false);
                                                                setUserIdToAddAssistantTo(null);
                                                            }
                                                        }}
                                                    >
                                                        <span className={theme.buttonContent}>
                                                            <Icon spriteSymbol={icAddAssistant} disabled={isDeleteUserDisabled} />
                                                            <span>{intl.formatMessage(messages.addAssistant)}</span>
                                                        </span>
                                                    </Button>
                                                    <Button
                                                        disabled={isDeleteUserDisabled}
                                                        visualStyle="text"
                                                        size="small"
                                                        onClick={() => {
                                                            onApprovalUserRemove(user.principalId);
                                                        }}
                                                    >
                                                        <Icon spriteSymbol={icRemoveApprovalUser} disabled={isDeleteUserDisabled} />
                                                    </Button>
                                                </div>
                                            </div>
                                            {user.assistants
                                                ? user.assistants?.map((assistant) => {
                                                    return (
                                                        <div className={theme.assistant}>
                                                            <div className={theme.assistantTitle}>
                                                                {assistant.assistantName || assistant.assistantLogin}
                                                            </div>
                                                            <Button
                                                                disabled={isDeleteUserDisabled}
                                                                visualStyle="text"
                                                                size="small"
                                                                onClick={() => {
                                                                    onApprovalAssistantRemove(
                                                                        user.principalId,
                                                                        assistant.assistantId,
                                                                    );
                                                                }}
                                                            >
                                                                <Icon spriteSymbol={icRemoveApprovalUser} disabled={isDeleteUserDisabled} />
                                                            </Button>
                                                        </div>
                                                    );
                                                })
                                                : null}
                                        </div>
                                    )}
                                </Draggable>
                            );
                        })}
                        {droppableProvided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};
