import { Tooltip } from 'antd';
import React from 'react';
import messages from './FooterButtons.messages';
import theme from './FooterButtons.scss';
import { TFooterButtonProps } from './FooterButtons.types';
import { showDeleteConfirmation } from '../AdminTools/Button/DeleteButton';
import { Button } from '../UIKit/components/Button/Button.component';

type TFooterButtonsProps = {
    buttons: TFooterButtonProps[];
    deleteQuestion?: string;
};

export const FooterButtons = (props: TFooterButtonsProps) => {
    const { buttons, deleteQuestion } = props;

    return (
        <div className={theme.generalButtons}>
            {buttons.map((button) => {
                return (
                    <Tooltip title={button.tooltip} key={button.key}>
                        <Button
                            size={button.size}
                            visualStyle={button.visualStyle}
                            key={button.key}
                            danger={button.danger}
                            disabled={button.disabled}
                            onClick={
                                button.children === messages.remove.id
                                    ? () =>
                                        showDeleteConfirmation({
                                            onDelete: (e) => button.onClick && button.onClick(e),
                                            deleteQuestion,
                                        })
                                    : button.onClick && button.onClick
                            }
                            dataTest={button.dataTest}
                        >
                            {button.children}
                        </Button>
                    </Tooltip>
                );
            })}
        </div>
    );
};
