import * as React from 'react';
import { FC } from 'react';
import { ExtendableSelect } from '../ExtendableSelect/ExtendableSelect.component';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import messages from './messages/FontFamilySelect.messages';
import { fontFamilyOptions } from '../../../../utils/configuration';

interface IFontFamilySelectProps {
    value: string;
    onChange?: (value: string) => void;
    disabled?: boolean;
    className?: string;
    renderNewOption?: boolean;
    tooltipTitle?: string;
}
type TFontFamilySelectPropsAllProps = WrappedComponentProps & IFontFamilySelectProps;

const FontFamilySelect: FC<TFontFamilySelectPropsAllProps> = (props) => (
    <ExtendableSelect
        renderNewOption={props.renderNewOption}
        className={props.className}
        baseOptions={fontFamilyOptions}
        validationRules={[{ required: true, message: props.intl.formatMessage(messages.formValidationMessage) }]}
        onChange={props.onChange}
        newValueLabel={props.intl.formatMessage(messages.formLabel)}
        disabled={props.disabled}
        value={{ value: props.value, label: props.value }}
        tooltipTitle={props.tooltipTitle}
    />
);
const FontFamilySelectWithIntl = injectIntl(FontFamilySelect);

export { FontFamilySelectWithIntl as FontFamilySelect };
