import React from 'react';
import messages from '../SystemProperties.messages';
import { useIntl } from 'react-intl';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

type TFooterAgreementProps = {
    handleDelete: () => void;
    onHideAgreement: () => void;
};

export const FooterAgreement = ({ handleDelete, onHideAgreement }: TFooterAgreementProps) => {
    const intl = useIntl();

    return (
        <div>
            <Button onClick={onHideAgreement}>{intl.formatMessage(messages.cancel)}</Button>
            <Button
                visualStyle="primary"
                danger
                onClick={() => {
                    handleDelete();
                    onHideAgreement();
                }}
            >
                {intl.formatMessage(messages.reset)}
            </Button>
        </div>
    );
};
