import React, { useState } from 'react';
import { TPreset } from '../../../../../../models/preset.types';
import icAttribute from '../../../../../../resources/icons/ic-attribute.svg';
import { AttributeType, AttributeTypeGroup, AttributeTypeValueTypeEnum } from '../../../../../../serverapi/api';
import messages from '../../../messages/Presets.messages';
import { AttributeTypeEditorDialog } from './AttributeTypeEditorDialog.component';
import { GroupSelectionDialog } from '../Dialog/GroupSelectionDialog/GroupSelectionDialog.component';
import { DeleteSelected, ExportButton, ImportButton, MoveSelected, TabHeader } from '../Header/TabHeader.component';
import { GroupedTypesTable } from '../util/GroupedTypesTable.component';
import theme from './AttributeTypesTab.scss';
import { useDispatch, useSelector } from 'react-redux';
import { TreeNode } from '../../../../../../models/tree.types';
import { createAttributeTypes, saveAttributeTypes } from '../../../../../../actions/attributeType.actions.types';
import { deleteAttributeTypeGroup } from '../../../../../../actions/attributeTypeGroup.actions';
import { useIntl } from 'react-intl';
import { deleteAttributeType, deleteAttributeTypesAndGroups } from '../../../../../../actions/attributeType.actions';
import { getCurrentLocale } from '../../../../../../selectors/locale.selectors';
import { attributeTypeFormatMessage } from './util/attributeTypesTab.utils';
import { LocalesService } from '../../../../../../services/LocalesService';
import { Alert } from 'antd';
import { getMetodologyElementsDeleteMessages } from '../util/metodologyElementsDeleteMessages.utils';
import { ButtonWithDropdown } from '../Header/ButtonWithDropdown.component';
import icFolder from '../../../../../../resources/icons/group.svg';
import icImport from '../../../../../../resources/icons/Import.svg';

type TProps = {
    preset: TPreset;
    disabled: boolean;
    attributeTypes: AttributeType[];
    attributeTypeGroups: AttributeTypeGroup[];
    serverNode: TreeNode;
};

type TActionProps = {
    changeAttributeTypeGroup: (selectedAttributeTypes: AttributeType[]) => void;
    editAttributeTypeGroup: (attributeTypeGroup: AttributeTypeGroup) => void;
    createAttributeTypeGroup: () => void;
    importAttributes: () => void;
    exportAttributes: (selectedAttributeTypes: AttributeType[]) => void;
};

type TFullProps = TProps & TActionProps;

// В задаче BPM-4144 убрали компонент AttributeTypesSetEditorTab.component
export const AttributeTypesTab = (props: TFullProps) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const currentLocale = useSelector(getCurrentLocale);

    const { preset } = props;

    const [searchFilter, setSearchFilter] = useState<string>('');
    const [attributeTypeDialogVisible, setAttributeTypeDialogVisible] = useState<boolean>(false);
    const [editableAttribute, setEditableAttribute] = useState<AttributeType>();
    const [selectedAttributeTypes, setSelectedAttributeTypes] = useState<AttributeType[]>([]);
    const [selectedAttributeTypeGroups, setSelectedAttributeTypeGroups] = useState<AttributeTypeGroup[]>([]);
    const [selectGroupDialogVisible, setSelectGroupDialogVisible] = useState<boolean>(false);
    const [saveRequired, setSaveRequired] = useState<boolean>(false);
    const selected = selectedAttributeTypes.length || selectedAttributeTypeGroups.length;
    const { disabled, attributeTypeGroups, attributeTypes, serverNode } = props;
    const { deleteMessage: deleteAttributesMessage, deleteGroupsMessage } = getMetodologyElementsDeleteMessages({
        selectedAttributeTypes,
        selectedAttributeTypeGroups,
    });

    function checkValueType(valueType: string): boolean {
        const attributeTypeValue: { [key in AttributeTypeValueTypeEnum]: string } = {
            NUMERIC: 'NUMERIC',
            INTEGER: 'INTEGER',
            STRING: 'STRING',
            MULTI_STRING: 'MULTI_STRING',
            BOOLEAN: 'BOOLEAN',
            DATE: 'DATE',
            TIME: 'TIME',
            DATE_TIME: 'DATE_TIME',
            PERIOD: 'PERIOD',
            SELECT: 'SELECT',
            MULTI_SELECT: 'MULTI_SELECT',
            NODE: 'NODE',
            DURATION: 'DURATION',
            PRINCIPAL: 'PRINCIPAL',
            JSON: 'JSON',
            FILE: 'FILE',
            URL: 'URL',
            EXTERNAL_DATA: 'EXTERNAL_DATA',
            TIME_WITHOUT_TIMEZONE: 'TIME_WITHOUT_TIMEZONE',
            DATE_TIME_WITHOUT_TIMEZONE: 'DATE_TIME_WITHOUT_TIMEZONE',
            QUERY_MULTI_SELECT: 'QUERY_MULTI_SELECT',
            QUERY_SELECT: 'QUERY_SELECT',
        };

        return Boolean(attributeTypeValue[valueType]);
    }

    return (
        <div className={theme.container}>
            <TabHeader
                buttons={[
                    ExportButton.build(
                        () => props.exportAttributes(selectedAttributeTypes),
                        intl.formatMessage(messages.export),
                        !(attributeTypes.length || attributeTypeGroups.length),
                        saveRequired,
                    ),
                    DeleteSelected.build(
                        () => {
                            if (selectedAttributeTypes.length && !selectedAttributeTypeGroups.length) {
                                dispatch(deleteAttributeType({ serverNode, attributeTypes: selectedAttributeTypes }));
                                setSelectedAttributeTypes([]);
                            }

                            if (selectedAttributeTypeGroups.length && !selectedAttributeTypes.length) {
                                dispatch(
                                    deleteAttributeTypeGroup({
                                        serverNode,
                                        attributeTypeGroups: selectedAttributeTypeGroups,
                                    }),
                                );
                                setSelectedAttributeTypeGroups([]);
                            }
                            if (selectedAttributeTypes.length && selectedAttributeTypeGroups.length) {
                                dispatch(
                                    deleteAttributeTypesAndGroups({
                                        serverNode,
                                        attributeTypes: selectedAttributeTypes,
                                        attributeTypeGroups: selectedAttributeTypeGroups,
                                    }),
                                );
                                setSelectedAttributeTypes([]);
                                setSelectedAttributeTypeGroups([]);
                            }
                            setSaveRequired(true);
                        },
                        disabled || !selected,
                        undefined,
                        intl.formatMessage(messages.deleteAttributesDialogTitle),
                        <Alert
                            message={
                                <>
                                    {deleteGroupsMessage}
                                    {deleteAttributesMessage}
                                </>
                            }
                            type="warning"
                        />,
                    ),
                    MoveSelected.build(() => setSelectGroupDialogVisible(true), disabled || !selected),
                ]}
                customButtons={
                    <ButtonWithDropdown
                        title={intl.formatMessage(messages.create)}
                        itemButtons={[
                            {
                                name: messages.newAttributeName,
                                dataTestId: 'new-attribute_type_button',
                                disabled: disabled || !attributeTypeGroups?.length,
                                onAction: () => {
                                    setEditableAttribute(undefined);
                                    setAttributeTypeDialogVisible(true);
                                },
                            },
                            {
                                disabled,
                                name: messages.addAttributeTypeGroup,
                                icon: icFolder,
                                onAction: props.createAttributeTypeGroup,
                            },
                            ImportButton.build(
                                props.importAttributes,
                                messages.import,
                                disabled,
                                saveRequired,
                                icImport,
                            ),
                        ]}
                    />
                }
                onSearchChange={setSearchFilter}
            />
            <GroupedTypesTable
                types={attributeTypes?.map((type) => ({
                    ...type,
                    groupId: type.attributeTypeGroup?.id,
                    valueType: type.valueType && attributeTypeFormatMessage(type.valueType),
                }))}
                typeGroups={attributeTypeGroups}
                searchFilter={searchFilter}
                actionsDisabled={disabled}
                icon={icAttribute}
                onSelectType={setSelectedAttributeTypes as any}
                selectedTypes={selectedAttributeTypes}
                onSelectGroup={setSelectedAttributeTypeGroups as any}
                selectedTypeGroups={selectedAttributeTypeGroups}
                onDeleteType={(type: AttributeType) => {
                    setSaveRequired(true);
                    dispatch(deleteAttributeType({ serverNode, attributeTypes: [type] }));
                }}
                onDeleteGroup={(group: AttributeTypeGroup) => {
                    setSaveRequired(true);
                    dispatch(
                        deleteAttributeTypeGroup({
                            serverNode,
                            attributeTypeGroups: [group],
                        }),
                    );
                }}
                onEditType={(record: AttributeType) => {
                    const editedAttributeType = attributeTypes.find((at) => record.id === at.id);
                    setEditableAttribute(editedAttributeType);
                    setAttributeTypeDialogVisible(true);
                }}
                onEditGroup={props.editAttributeTypeGroup}
                columns={[
                    {
                        label: intl.formatMessage(messages.format),
                        width: 130,
                        minWidth: 130,
                        maxWidth: 130,
                        dataKey: 'valueType',
                    },
                    {
                        label: intl.formatMessage(messages.description),
                        dataKey: 'multilingualDescription',
                        width: 130,
                        cellRenderer: ({ rowData }) =>
                            LocalesService.internationalStringToString(rowData.multilingualDescription, currentLocale),
                    },
                ]}
                titleDeleteMessage={messages.deleteAttributesDialogTitle}
                deleteGroupMessage={messages.deleteAttributeGroups}
                deleteElMessage={messages.deleteAttributes}
            />
            {attributeTypeDialogVisible && (
                <AttributeTypeEditorDialog
                    preset={props.preset}
                    isNewAttribute={!editableAttribute}
                    attributeTypeGroups={attributeTypeGroups}
                    dialogVisible={attributeTypeDialogVisible}
                    attributeType={editableAttribute}
                    onCancel={() => {
                        setEditableAttribute(undefined);
                        setAttributeTypeDialogVisible(false);
                    }}
                    onOk={(attributeType: AttributeType) => {
                        const editedAttributeType = props.attributeTypes.find((at) => attributeType.id === at.id);
                        let valueType: AttributeTypeValueTypeEnum | undefined;

                        if (attributeType.valueType && checkValueType(attributeType.valueType)) {
                            valueType = attributeType.valueType;
                        } else {
                            valueType = editedAttributeType?.valueType;
                        }
                        if (!editableAttribute) {
                            dispatch(
                                createAttributeTypes({
                                    serverNode,
                                    preset,
                                    attributeType: { ...attributeType, valueType },
                                }),
                            );
                        } else {
                            dispatch(
                                saveAttributeTypes({
                                    serverNode,
                                    preset,
                                    attributeTypes: [{ ...attributeType, valueType }],
                                }),
                            );
                        }

                        setEditableAttribute(undefined);
                        setAttributeTypeDialogVisible(false);
                    }}
                />
            )}
            {selectGroupDialogVisible && (
                <GroupSelectionDialog
                    groups={attributeTypeGroups}
                    onSubmit={(group) => {
                        if (group) {
                            const filteredSelectedAttributeTypes = props.attributeTypes.filter((at) =>
                                selectedAttributeTypes.find((s) => s.id === at.id),
                            );
                            props.changeAttributeTypeGroup(
                                filteredSelectedAttributeTypes.map((at) => ({
                                    ...at,
                                    attributeTypeGroup: group as AttributeTypeGroup,
                                })),
                            );
                        }
                        setSelectGroupDialogVisible(false);
                        setSaveRequired(true);
                    }}
                    onClose={() => setSelectGroupDialogVisible(false)}
                />
            )}
        </div>
    );
};
